import styled from "@emotion/styled";
import { useMemo } from "react";

export const Filter = ({ column }: any) => {
  return (
    <div style={{ marginTop: 5 }}>
      {column.canFilter && column.render("Filter")}
    </div>
  );
};

export const DefaultColumnFilter = ({
  column: {
    filterValue,
    setFilter,
    preFilteredRows: { length },
  },
}: any) => {
  return (
    <input
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`search (${length})`}
    />
  );
};

export const SelectColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id, selectedFilteredRows },
}: any) => {
  const options = useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row: any) => {
      options.add(row.values[id]);
    });
    
    return [
      //@ts-ignore
      ...options.values(),
    ];
  }, [id, preFilteredRows]);

  return (
    <>
    <select
      id="custom-select"
      value={filterValue}
      onChange={(e) => {
        e.stopPropagation();
        e.preventDefault();
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  </>
  );
};
