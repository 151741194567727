import Page from "components/layout/Page";
import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { changePageName } from 'hooks/util/usePageName';
import UserSubscriptionCoupon from "components/pages/UIMS/coupons/UserSubscriptionCoupon/userSubscriptionCoupon";
import SubscriptionCoupon from "components/pages/UIMS/coupons/SubscriptionCoupon";
import GeneralCoupon from "components/pages/UIMS/coupons/NomalCoupon";

const Elem: React.FC<any> = ({ children }) => {
  const [tab, setTab] = useState(1);

  useEffect(() => {
    const pageName = 'UIMS-coupon';
    changePageName(pageName);
  },[]);

  return (
    <>
      <Page>
        <UimsWrapper>
          <TabContainer>
            <TapContainer>
              <Menu
                //@ts-ignore
                isSeleted={tab === 1}
                onClick={() => setTab(1)}
              >
                구독권 사용자 관리
              </Menu>
              <Menu
                //@ts-ignore
                isSeleted={tab === 2}
                onClick={() => setTab(2)}
              >
                구독권 생성 및 관리
              </Menu>
              <Menu
                //@ts-ignore
                isSeleted={tab === 3}
                onClick={() => setTab(3)}
              >
              일반 쿠폰 생성 및 관리
              </Menu>
            </TapContainer>
            {tab === 1 && 
              <UserSubscriptionCoupon 
              tab={tab}
            />}
            {tab === 2 && 
              <SubscriptionCoupon
              typeId={tab}
            />}
            {tab === 3 && 
              <GeneralCoupon 
              tab={tab}
            />}
          </TabContainer>
        </UimsWrapper>
      </Page>
    </>
  );
};

export default Elem;

const UimsWrapper = styled.div`
  width: 100%;
  height: 1000px;
  display: flex;
  flex-direction: column;
`;
const TabContainer = styled.div`
  width: 100%;
  height: 43px;
`;
const TapContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
const Menu = styled.button`
  width: 250px;
  @media (max-width: 1500px) {
    width: 200px;
  }
  height: 43px;
  top: 157px;
  left: 100px;
  font-size: 12px;
  text-align: center;
  line-height: 43px;
  font-size: 12px;
  cursor: pointer;

  background-color: ${(props) =>
    //@ts-ignore
    props.isSeleted ? "white" : "#DFDFDF"};
  color: ${(props) =>
    //@ts-ignore
    props.isSeleted ? "black" : "white"};
  border: ${(props) =>
    //@ts-ignore
    props.isSeleted ? "1px solid black;" : "white"};
  border-bottom-style: none;
`;