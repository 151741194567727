import Page from "components/layout/Page";
import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import UserTable from "./UserTable";
import UserData  from "./UserData";
import { useGetAllSubscribeUsers } from "hooks/api";
import { changePageName } from 'hooks/util/usePageName';


interface IPeopleData {
  allUsers:number;
  allSubs : number;
  deleteUser:number;
  unSubs:number
}

const Elem: React.FC<any> = ({ children }) => {
  const [checkdata, setData] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const { data } = useGetAllSubscribeUsers();

  const onRefresh = () => {
    setLoading(true);
    // refetchGetUserData();
    window.location.reload()
    setLoading(false);
  };

  const peopleData:IPeopleData = {
    allUsers   : data?.allUser,
    allSubs    : data?.allSubs,
    deleteUser : data?.deleteUser,
    unSubs     : data?.unSubs
  }
  

  const allUserData = data?.data;

  useEffect(() => {
    const pageName = 'UIMS-users';
    changePageName(pageName);
  },[]);

  return (
    <>
      <Page>
        <UimsWrapper>
        <RenderingBox>
            <RenderingButton onClick={onRefresh}>
              <img src="/images/Ellipse 16.svg" />
              <ButtonText> &nbsp; refresh</ButtonText>
            </RenderingButton>
          </RenderingBox>
          <DataWrapper>
            <UserData peopleData={peopleData} checked={checkdata} data={data} />
          </DataWrapper>
          <UimsUserDataWrapper>
            <UserTable getCheckListInUIMS={setData} data={allUserData}/>
          </UimsUserDataWrapper>
        </UimsWrapper>
      </Page>
    </>
  );
};

export default Elem;

const UimsWrapper = styled.div`
  width: 100%;
  height: 1000px;
`
const DataWrapper = styled.div`
  width: 100%;
  height: 100px;
  background-color: aqua;
  display: flex;
  flex-direction: row;
`

const UimsUserDataWrapper = styled.div`
  width: 100%;
  height: 100%;
`;
const RenderingBox = styled.div`
  width: 100%;
`;

const RenderingButton = styled.button`
  float: right;
  margin-left: auto;
  background-color: white;
  border: 0;
  display: flex;
  flex-direction: row;
  margin-bottom: -21px;
`;
const ButtonText = styled.span`
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  color: #e6e6e6;
  
  :hover {
    color: black;
  }
`;