import React, { useState, useEffect } from "react";
import Page from "components/layout/Page";
import styled from "@emotion/styled";
import { UncheckedTable } from "components/ui";
import { changePageName } from 'hooks/util/usePageName';

const GeneralCouponTable: React.FC<any> = ({ data }) => {
  console.log(data)
  const columnData = [
    {
      accessor: "url",
      Header: () => null,
      id: "url",
      // disableSortBy: true,
      disableFilters: true,
      disableCheckbox: true,
    },
    {
      accessor: "id",
      Header: "DBNumber",
      id: "id",
      // disableSortBy: true,
      disableFilters: true,
      disableCheckbox: true,
    },
    {
      accessor: "name",
      Header: "쿠폰이름",
      id: "name",
      // disableSortBy: true,
      disableFilters: true,
      disableCheckbox: true,
    },
    {
      accessor: "generate",
      Header: "총 개수",
      id: "generate",
      // disableSortBy: true,
      disableFilters: true,
      disableCheckbox: true,
    },
    {
      accessor: "success",
      Header: "사용 개수",
      id: "success",
      // disableSortBy: true,
      disableFilters: true,
      disableCheckbox: true,
    },
    {
      accessor: "noUse",
      Header: "남은 개수",
      id: "noUse",
      // disableSortBy: true,
      disableFilters: true,
      disableCheckbox: true,
    },
  ];

  const hiddenColumn = ["id"];

  return (
    <>
      <UncheckedTable
        columnData={columnData}
        bodyData={data || []}
        filters={["name"]}
        hiddenColumn={hiddenColumn}
      />
    </>
  );
};

export default GeneralCouponTable;