import styled from "@emotion/styled";
import Page from "components/layout/Page";
import { useEffect } from 'react';

const Elem: React.FC<any> = ({ children }) => {
  const handle404Error = () => {
    const token = localStorage.getItem('token');
    if (token) {
      window.location.href = '/lms'
    } else {
      window.location.href = '/login'
    }
    
  }

  useEffect(() => {
    handle404Error();
  })
  return (
    <Page>
      <h1>DAMS</h1>
    </Page>
  );
};

export default Elem;
