import { useEffect, useState } from 'react';
import SelectFilter  from "components/ui/Select";

import styled from "@emotion/styled";

interface Option {
  className?:string;
  value: number;
  name: string;
}

const isUpload:Option[] = [
  { value: 1, name: "전체" },
  { value: 2, name: "업로드 완료" },
  { value: 3, name: "미업로드" },
  { value: 4, name: "에러" }
]; //원래는 select 태그 안에 들어가는 애들을 배열로 만들어준다.

const isBook:Option[]= [
  { value: 1, name: "전체" },
  { value: 2, name: "북" },
  { value: 3, name: "봉투" },
]

const isAdditional:Option[] = [
  { value: 1, name: "전체" },
  { value: 2, name: "없음" },
  { value: 3, name: "있음" },
];

const isPay:Option[] = [
  { value: 1, name: "전체" },
  { value: 2, name: "완료" },
  { value: 3, name: "실패" },
];

const deliveryType:Option[] = [
  { value: 1, name: "정기배송" },
  { value: 2, name: "추가배송" }
];

interface FileterInfo {
  isUpload    : number;
  isBook      : number;
  isAdditional: number;
  isPay       : number;
  deliveryType: number;
};

const Elem:React.FC<any> = ({ getFilterInfo }) => {
  const [ fileterOptions, setFilterOptions ] = useState<FileterInfo>({
    isUpload  : 1,
    isBook    : 1,
    isAdditional : 1,
    isPay: 1,
    deliveryType: 1
  });

  const handleisUpload = async ( selectedOption:Option ) => {
    setFilterOptions((preSelectedOptions) => ({
      ...preSelectedOptions,
      isUpload: selectedOption?.value
    }))
  };

  const handleisBook = async ( selectedOption:Option ) => {
    setFilterOptions((preSelectedOptions) => ({
      ...preSelectedOptions,
      isBook: selectedOption?.value
    }))
  };

  const handleisAdditional = async ( selectedOption:Option ) => {
    setFilterOptions((preSelectedOptions) => ({
      ...preSelectedOptions,
      isAdditional: selectedOption?.value
    }))
  };

  const handleisPay = async ( selectedOption:Option ) => {
    setFilterOptions((preSelectedOptions) => ({
      ...preSelectedOptions,
      isPay: selectedOption?.value
    }))
  };

  const handleDeliveryType = async ( selectedTypeOption: Option ) => {
    setFilterOptions((preSelectedTypeOption) => ({
      ...preSelectedTypeOption,
      deliveryType: selectedTypeOption?.value
    }))
  }

  useEffect(() => {
    if (fileterOptions) {
      getFilterInfo(fileterOptions)
    }
  },[fileterOptions])

  return (
    <FilterContainer>
      <FilterWrapper>
        <DropDownContainer>
          <FilterName>업로드 여부</FilterName>
          <DropDownWrapper>
            <SelectFilter
              options={isUpload}
              onChange={handleisUpload}
            />
          </DropDownWrapper>
        </DropDownContainer>
        <DropDownContainer>
          <FilterName>배송 타입</FilterName>
          <DropDownWrapper>
            <SelectFilter
              options={isBook}
              onChange={handleisBook}
            />
          </DropDownWrapper>
        </DropDownContainer>
        <DropDownContainer>
          <FilterName>추가 상품</FilterName>
          <DropDownWrapper>
            <SelectFilter 
              options={isAdditional}
              onChange={handleisAdditional}
            />
          </DropDownWrapper>
        </DropDownContainer>
        <DropDownContainer>
          <FilterName>결제 여부</FilterName>
          <DropDownWrapper>
            <SelectFilter 
              options={isPay}
              onChange={handleisPay}
            />
          </DropDownWrapper>
        </DropDownContainer>
        <DropDownContainer className="last">
          <FilterName>배송타입</FilterName>
          <DropDownWrapper>
            <SelectFilter 
              options={deliveryType}
              onChange={handleDeliveryType}
            />
          </DropDownWrapper>
        </DropDownContainer>
      </FilterWrapper>
    </FilterContainer>
  )
};

export default Elem;

const FilterContainer = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  height: 70px;
  display: flex;
  flex-direction: row;
`;

const FilterWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const DropDownContainer = styled.div`
  width: 130px;
  margin-right: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.last {
    margin-right: 0;
  }
`;
const FilterName = styled.div`
  height: 20px;
  font-size: 15px;
  color: black;
  font-style: normal;
  font-weight: 500;
`;
const DropDownWrapper = styled.div`
  width: 100%;
  height: 30px;
`;


