import styled from '@emotion/styled';
import { useState, forwardRef, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ko } from "date-fns/esm/locale";

const YearMonthPicker:React.FC<any> = ({ getYearMonth }) => {
  const [ startDate , setStartDate ] = useState<any>();
  const ExampleCustomInput = forwardRef(({ value, onClick }: any, ref): any => (
    // @ts-ignore
    // ref={ref}
    <DatePickerButton className="example-custom-input" onClick={onClick}>
      {value}
    </DatePickerButton>
  ));

  useEffect(() =>{
    if (startDate){
      getYearMonth(startDate)
    }
  }, [startDate])

  return (
    <Container>
      <DatePickerContainer
        locale={ko}
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        dateFormat="yyyy년 MM월"
        showMonthYearPicker
        customInput={<ExampleCustomInput />}
      />
    </Container>
  );
};

export default YearMonthPicker;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
`;
const DatePickerContainer = styled(DatePicker)`
  display: flex;
  flex-direction: column;
`;
const DatePickerButton = styled.button`
  background-color: white;
  height: 30px;
  border: 1px solid #4d4d4d;
  border-radius: 5%;
  min-width: 180px;
  font-size: 15px;
`;
