import styled from '@emotion/styled';
import GeneralCouponTable from "./GeneralCouponTable";

const Elem:React.FC<any> = ({ generalCouponList }) => {
  return (
    <Container>
      <Title>쿠폰 현황</Title>
      <GeneralCouponTable data={generalCouponList} />
    </Container>
  )
}

export default Elem;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  font-weight: normal;
  margin-top: 10px;
`;

const FilterInput = styled.input`
  border: 1px solid black;
  border-radius: 5px;
  padding: 0.4rem;
  margin-bottom: 3px;
  margin-right: auto;
  margin-top: 10px;
`;
