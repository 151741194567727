import { AxiosError } from 'axios';
import request from 'common/request';
import { useQuery } from 'react-query';

const useGetAllSubscribeUserList = (options?: any) => {
  return useQuery<any, AxiosError>(
    ['subsriptionCouponUser'],
    async () => {
      const { data } = await request.get<any>(`/coupon/all/subs/user`);
      
      return data?.allSubscriptonUser
    },
    {
      cacheTime: 30 * 1000,
      ...options,
    }
  );
};

export default useGetAllSubscribeUserList;
