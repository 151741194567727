import styled from "@emotion/styled";
import Page from "components/layout/Page";
// import RegulerDeliveryTable from "./DeliveryTable";
import { changePageName } from 'hooks/util/usePageName';
import { useEffect, useState } from "react";
import History from "./history";

const Payhistory: React.FC<any> = ({ children }) => {
  const [ loading, setLoading ] = useState(false);

  const onRefresh = () => {
    setLoading(true);
    // refetchGetUserData();
    window.location.reload()
    setLoading(false);
  };

  useEffect(() => {
    const pageName = 'PMS-payhistory';
    changePageName(pageName);
  },[]);

  return (
    <>
      <Page>
        <PMSWrapper>
          <TopContainer>
            <RenderingBox>
              <RenderingButton onClick={onRefresh}>
                <img src="/images/Ellipse 16.svg" />
                <span> &nbsp; refresh</span>
              </RenderingButton>
            </RenderingBox>
          </TopContainer>
          <TableContaier>
            <History/>
          </TableContaier>
        </PMSWrapper>
      </Page>
    </>
  )
}

export default Payhistory;

const PMSWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const TopContainer = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
`;
const RenderingBox = styled.div`
display: flex;
  margin-bottom: 20px;
  height: 100%;
  margin-left: auto;
`;
const RenderingButton = styled.button`
  height: 100%;
  float: right;
  margin-left: auto;
  background-color: white;
  border: 0;
  display: flex;
  flex-direction: row;

  span {
    margin: 0;
  font-size: 18px;
  font-weight: 500;
  color: #e6e6e6;
  :hover {
    color: black;
  }
  }
`;
const TableContaier = styled.div`
  width: 100%;
  /* height: 300px; */
  min-height: 60vh;
  display: flex;
  flex-direction: column;
`;