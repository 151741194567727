import styled from "@emotion/styled";
import Header from "./Header";
import SideTapbar from './SideTapBar';

interface IPage {
  type?: string;
  children?: any;
}
const Layout = styled.div`
  width: 100%;
  min-height: 100vh;
  height: auto;
  display: block;
  position: relative;
  z-index: 1;
  margin-bottom: 20px;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
`
const Content = styled.div`
  margin: 0 0;
  /* overflow: auto; */
  width: 100%;
  /* background-color: aqua; */
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  padding: 10px;
`;

const Page: React.FC<IPage> = ({ children, type }) => {
  return (
    <Container>
      <Header disabled/>
      <Content>
        <SideTapbar/>
        <Layout className={type}>{children}</Layout>
      </Content>
    </Container>
  )
};

export default Page;
