import React from "react";
import styled from "@emotion/styled";
import { Table } from "components/ui";
import { CheckOutlined } from "@ant-design/icons";

const isUseCell = ({ value }: any) => {
  if (value === false) {
    return <NoUploadText>사용완료</NoUploadText>
  } else {
    return <UplodadText>사용중</UplodadText>
  }
}

const UserSubsCoupon: React.FC<any> = ({ getCheckListInLMS=[], value, data }) => { 
  console.log('data', data)
  const columnData = [
    {
      accessor: "id",
      Header: "DBNumber",
      id: "id",
      // disableSortBy: true,
      disableFilters: true,
      disableCheckbox: true,
    },
    {
      accessor: "couponName",
      Header: "쿠폰 이름",
      id: "couponName",
      disableFilters: true,
    },
    {
      accessor: "number",
      Header: "쿠폰 번호",
      id: "number",
      disableFilters: true,
    },
    {
      accessor: "name",
      Header: "고객명",
      id: "name",
      disableSortBy: true,
      disableFilters: true,
    },
    {
      accessor: "mobile",
      Header: "전화번호",
      id: "mobile",
      disableSortBy: true,
      disableFilters: true,
    },
    {
      accessor: "created_at",
      Header: "사용 날짜",
      id: "created_at",
      disableSortBy: true,
      disableFilters: true,
    },
  ];

  const hiddenColumn = ["id"];

  const getCheckList = async (e: any) => {
    getCheckListInLMS(e);
  };
  
  return (
    <Container>
      <Table
        columnData={columnData}
        bodyData={data || []}
        getCheckList={getCheckList}
        filters={["couponNumber"]}
        filter={value}
        hiddenColumn={hiddenColumn}
      />
    </Container>
  );
};

export default UserSubsCoupon;

const Container = styled.div`
  margin-top: 10px;
  width: 100%;
`;
const UplodadText = styled.p`
  font-size: 12px;
  color: green;
`;
const NoUploadText = styled.p`
  font-size: 12px;
  font-weight: bold;
  color: red;
`;