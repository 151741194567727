import React, { useEffect, useState } from "react";
import { usePostLogout } from "hooks/api";
import { NavLink } from "react-router-dom";
import styled from "@emotion/styled";

const Header: React.FC<{ disabled?: boolean }> = ({ disabled }) => {
  //   const { user, showOnboarding } = useCurrentUser();
  const { mutateAsync: postLogout } = usePostLogout();
  const [ pageName, setpageName ] = useState("" || null)

  const user = { name: "YSS" };

  const Logout = async () => {
    await postLogout({})
    localStorage.removeItem('token');
    window.location.href = '/login'
  }


  useEffect(() => {
    const getPageName = localStorage.getItem("page");
    //@ts-ignore
    setpageName(getPageName)
  },[pageName])

  return (
    <HeaderWrapper>
      <HeaderContextWrapper>
        <PageConatainder>{pageName}</PageConatainder>
        <LogoContainser>
          <HeaderMainContext>PIMS</HeaderMainContext>
          <SubTitleContext>
            <SubTitleFontColor>P</SubTitleFontColor>hotypeta&nbsp;
            <SubTitleFontColor>I</SubTitleFontColor>ntegrated&nbsp;
            <SubTitleFontColor>M</SubTitleFontColor>anagement&nbsp;
            <SubTitleFontColor>S</SubTitleFontColor>tystem
          </SubTitleContext>
        </LogoContainser>
        <LogoutButtonFill>
          <LogoutButton onClick={Logout}>Logout</LogoutButton>
        </LogoutButtonFill>
      </HeaderContextWrapper>
    </HeaderWrapper>
  );
};

export default Header;

// import useCurrentUser from "hooks/user";
const HeaderWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 66px;
  width: 100%;
`;

const HeaderContextWrapper = styled.div`
  width: 100%;
  height: 56px;
  margin-bottom: 27px;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  align-items: baseline;
  border-bottom: solid 2px rgba(232, 232, 232, 1);;
`;

const HeaderMainContext = styled.p`
  font-style: normal;
  font-weight: 800;
  font-size: 43px;
  line-height: 100%;
  margin: 0;
  letter-spacing: -0.04em;
`;

const SubTitleFontColor = styled.span`
  color: #0e0465;
  font-weight: bold;
`;

const SubTitleContext = styled.p`
  margin-left: 13px;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  letter-spacing: -0.05em;
`;
const LogoutButtonFill = styled.div``;

const LogoutButton = styled.button`
  font-weight: 500;
  font-size: 15px;
  background-color: white;
  margin-right: 20px;

  &:hover {
    background-color: rgba(232, 232, 232, 1);
    border-radius: 10%;
  }
`;

const NavButtonFill = styled.div``;

const NavigationWrapper = styled.div`
  height: 50px;
  align-items: center;
  padding: 0px 25px 0px 0px;
  /* z-index: 998; */
  width: 100%;
  background-color: rgba(232, 232, 232, 1);
`;

const NavigationItemsWrapper = styled.div`
  margin-left: 75px;
  width: 100%;
  display: flex;

  svg {
    overflow: visible !important;
    transition: transform 0.25s;
  }

  a {
    text-decoration: none;
  }

  a.active {
    .btn-wrapper {
      background-color: var(--grey-lighter);
      color: var(--experimental-blue);
    }
  }

  .btn-wrapper {
    border-radius: 5px;
    overflow: hidden;
    transition: background-color 0.25s;
    &:hover {
      background-color: var(--grey-lighter);
    }
  }
`;

const NavButtonWrapper = styled.div`
  display: flex;
  font-size: 14px;
  color: var(--black);
  padding: 10px 20px;

  .wide-icon {
    width: 16px;
    margin-top: -1px;
  }

  * {
    margin-right: 5px;
  }
`;

const LogoContainser = styled.div`
  display: flex;
  flex-direction: row;
  width: 550px;
  margin: 0 auto;
  height: 100%;
  align-items: baseline;
`;
const PageConatainder = styled.div`
  height: 100%;
  font-weight: 500;
  font-size: 30px;
  line-height: 100%;
  color: rgba(136, 136, 136, 1);
  margin-left: 10px;
`;