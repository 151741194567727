import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { usePostBackedUser } from "hooks/api";
import { CloseOutlined } from '@ant-design/icons';
import LoadingOverlay from 'components/ui/PIMS/LoadingOverlay';
import SelectFilter  from "components/ui/Select";
import BackedSubsModalTable from "./BackedSubsModalTable";
import _ from 'lodash';

interface Option {
  className?:string;
  value: number;
  name: string;
  additionalPrice?: number
}

const backedSubs:Option[] = [
  { value: 1, name: "1개월(+ 3,780)", additionalPrice: 3780 },
  { value: 2, name: "2개월(+ 7,560)", additionalPrice: 7560 },
  { value: 3, name: "3개월(+ 11,340)", additionalPrice: 11340},
  { value: 4, name: "4개월(+ 12,960)", additionalPrice: 12960},
  { value: 5, name: "5개월(+ 16,200)", additionalPrice: 16200 },
  { value: 6, name: "6개월(+ 19,440)", additionalPrice: 19440 }, 
  { value: 7, name: "7개월(+ 22,680)", additionalPrice: 22680 },
  { value: 8, name: "8개월(+ 25,920)", additionalPrice: 25920},
  { value: 9, name: "9개월(+ 29,160)", additionalPrice: 29160 },
  { value: 10, name: "10개월(+ 32,400)", additionalPrice: 32400 },
  { value: 11, name: "11개월(+ 35,640)", additionalPrice:35640 },
  { value: 12, name: "12개월(+ 38,880)", additionalPrice: 38880 }
]

interface FileterInfo {
  backedSubs?    : number;
  additionalPrice  : number;
}

interface BackedUser {
  id          : number,
  name        : string,
  photyId     : string,
  isSubsCoupon: Boolean,
  preAmount   : number
  afterAmount? : number
}


const Elem: React.FC<any> = ({ showModal, closeModal, backedData  }) => {
  const [ loading, setLoading ] = useState(false);
  const [ filterOptions ] = useState<FileterInfo>({
    backedSubs:1,
    additionalPrice: 3780
  })

  const [ resultData, setresultData] = useState<BackedUser[]>([]);
  const addAfterAmount = async (backedData:BackedUser[]) => {
    for (const user of backedData) {
      user["afterAmount"] = user["preAmount"] + filterOptions["additionalPrice"];
    }

    setresultData(backedData)
  }

  const handleBackedSubs = async (selectedOption: any ) => {
    let backedUserList = [];
    for (const user of backedData) {
      const tempUserData = { ...JSON.parse(JSON.stringify(user))};
      tempUserData["afterAmount"] = tempUserData["preAmount"] + selectedOption.additionalPrice;
      backedUserList.push(tempUserData);
    }
    setresultData(backedUserList);
  }

  useEffect(() => {
    addAfterAmount(backedData)
  }, [])
  
  return (
    <>
      {showModal? (
        <Background >
        <ModalContainer onClick={e => e.stopPropagation()}>
          <TapContainer>
            <Title>소급구독 적용</Title>
            <CloseButtonContainer>
              <CloseButton onClick={closeModal}></CloseButton>
            </CloseButtonContainer>
          </TapContainer>
          <BodyContainer>
            <ButtonContainer>
              <ButtonTitle>소급 구독 개월</ButtonTitle>
              <DropDownWrapper>
                <SelectFilter
                  options={backedSubs}
                  onChange={handleBackedSubs}
                />
              </DropDownWrapper>
            </ButtonContainer>
            <Table>
              <ButtonTitle className="table">적용대상</ButtonTitle>
              <BackedSubsModalTable data={resultData} />
            </Table>
          </BodyContainer>
          < LoadingOverlay loading={loading}/>
        </ModalContainer>
      </Background>):null}
    </>
  );
};

export default Elem;

const Background = styled.div`
  position:fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0,0,0,0.50);
  z-index: 0;
`

const ModalContainer = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-height: 80%;
  width: 500px;
  height: 90%;
  /* padding: 16px; */
  background-color: white;
  border-radius: 10px;
  text-align: center;
  display:flex;
  flex-direction: column;
`

const CloseButton = styled(CloseOutlined)`
  position: absolute;
  top: 19px;
`
const CloseButtonContainer =styled.div`
  height: 10%;
  width: 10%;
  margin-left: auto;
`
const Title = styled.div`
  display: flex;
  align-items: center;
  font-size: 30px;
  margin-top: 16px;
  margin-left: 16px;
  margin-bottom: 16px;
`;

const TapContainer = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  border-bottom: 3px solid #D9D9D9;
`;

const BodyContainer =styled.div`
  width: 90%;
  height: 100%;
  margin: 30px;
  min-height: 30vh;
  background-color: lightgreen;
`

const ButtonContainer = styled.div`
  width: 127px;
  height: 60px;
  display: flex;
  flex-direction: column;
`;

const ButtonTitle = styled.div`
  margin-right: auto;
  font-size: 14px;

  &.table{
    margin-bottom: 10px;
  }
`
const DropDownWrapper = styled.div`
  width: 100%;
  height: 30px;
  margin-top: 7px;
`;

const Table = styled.div`
  width:100%;
  height: 100px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  background-color: aquamarine;
`;


