import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import DataTable from "components/pages/UIMS/coupons/UserSubscriptionCoupon/DataTable";

const Elem: React.FC<any> = ({ children }) => {
  const [ filter, setFilter ] = useState("");

  const handleInputChange = async (e:any) => {
    const { value } = e.currentTarget;
    setFilter(value)
  }

  return (
    <Container>
      <SmallTextBox>구독권을 사용한 고객 관리를 위한 페이지 입니다.</SmallTextBox>
      <FilterInput
          value={filter}
          onChange={handleInputChange}
          placeholder="쿠폰번호, 이름"
        />
      <DataTable
        value={filter}
      />
    </Container>
  );
};

export default Elem;

const Container = styled.div`
  margin-top: 3px;
  width: 100%;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
`;

const SmallTextBox = styled.div`
margin-top: 10px;
`
const FilterInput = styled.input`
  border: 1px solid black;
  border-radius: 5px;
  padding: 0.4rem;
  margin-bottom: 3px;
  margin-right: auto;
  margin-top: 10px;
`;
