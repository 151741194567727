const typography = `
  html body {
    font-family: globalFont;
    font-weight: 400;
    line-height: 1.28;
    font-size: 14px;
    color: var(--black);

    h1, h2, h3, h4, h5, h6 {
      color: inherit;
    }

    h1 {
      font-size: 30px;
      font-weight: 400;
      margin-bottom: 12px;
    }
    h2 {
      font-size: 21px;
      font-weight: 500;
      line-height: 27px;
      margin-bottom: 12px;
      display: flex;
      align-items: center;
    }
    h3 {
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      margin-bottom: 12px;
    }
    h4, h5, h6 {
      margin-bottom: 15px;
    }

    p {
      margin-bottom: 15px !important;
    }

    a {
      color: var(--experimental-blue);
      text-decoration: none;
    }

  }
`;

export default typography;
