import React, { useEffect, useMemo, useState, Fragment, } from "react";
import { 
  useTable } from "react-table";
import styled from "@emotion/styled";

interface ITable {
  columnData: any;
  bodyData: any;
  props?: any;
  hiddenColumn? : any;
}

const Table: React.FC<ITable> = ({ columnData, bodyData, hiddenColumn }) => {
  const data = useMemo(() => bodyData, [bodyData]);
  const columns = useMemo(() => columnData, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
    initialState: { hiddenColumns: hiddenColumn},
  });

  return (
    <DataTable {...getTableProps()}>
      <DataTableHead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <HeadTh {...column.getHeaderProps()}>{column.render('Header')}</HeadTh>
            ))}
          </tr>
        ))}
      </DataTableHead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </DataTable>
  );
};

export default Table;

const DataTable = styled.table`
width: 100%;
`;

const DataTableHead = styled.thead`
  height: 25px;
  border-bottom: 1px solid #D4D4D4;
  border-top:1px solid #D4D4D4;
  /* background-color: aqua; */
`;

const HeadTh = styled.th`
  padding: 8px;
`

