import styled from "styled-components";
import { PacmanLoader, ClipLoader } from 'react-spinners';


interface LoadingOverlayProps {
  loading: boolean;
}

const LoadingOverlay:React.FC<LoadingOverlayProps> = ({ loading }) => {
  return (
  <>
    {loading && (
      <Overlay>
        <ClipLoader color="white" size={50} />
      </Overlay>
    )}
  </>)
};

export default LoadingOverlay

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;