import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import SelecteGenrateCoupon from 'components/pages/UIMS/coupons/GeneralCoupon/SelectGenerateCoupon';
import BottomContainer from './GeneralCoupon/BottomContainer';


const Elem: React.FC<any> = ({ children }) => {

  return (
    <CouponContainer>
      <TextContainer>
        똑같은 쿠폰번호로 일괄적으로 사용하는 일반 쿠폰을 생성 하는 곳입니다. <br />구독권과의 차이를 위해 맨 앞자리는 쿠폰번호에서 ‘G’ 이 일괄적으로 맨 앞에 추가가 됩니다. 
      </TextContainer>
      <TopContainer>
        < SelecteGenrateCoupon />
      </TopContainer>
      <BottomContainer/>
    </CouponContainer>
  );
};

export default Elem;

const CouponContainer = styled.div`
  width: 100%;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
`;

const TextContainer = styled.div`
  width: 600px;
`;

const TopContainer = styled.div`
  width: 100%;
  min-height: 100px;
  background-color: aquamarine;
  border-bottom: 3px solid #D9D9D9;
`;

