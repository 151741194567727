import { defaultTheme } from '@xstyled/emotion';

const colors = {
  ...defaultTheme.colors,
  text: 'var(--black)',
  background: 'var(--white-background)',
  primary: 'var(--navy)',
  header: 'var(--ascent-grey)',
};

const sizes = {
  siderWidth: '200px',
  headerHeight: '61px',
};

const Theme = {
  ...defaultTheme,
  sizes,
  screens: {
    ...defaultTheme.screens,
    ix: '375px',
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64],
  fontWeights: {
    body: 400,
    heading: 700,
  },
  fonts: {
    globalFont:
      'GT-America-Standard,"Helvetica Neue",Helvetica,Arial,sans-serif',
    // '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  },
  colors,
};

export default Theme;
