import { useMutation, UseMutationOptions } from "react-query";
import axios, { AxiosError } from "axios";
import request from "common/request";
type deleteVariable = {};

export const useDeletZipList = (
  options?: UseMutationOptions<any, AxiosError, deleteVariable>
) => {
  return useMutation<any, AxiosError, deleteVariable>(
    async ({ }) => {
      try {
        const response = await request.post(`/zip/list/delete`);
        return response;
      } catch (e) {
        alert("There was an error. Please try again later.");
      }
    },
    {
      ...options,
    }
  );
};

export default useDeletZipList;