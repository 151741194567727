import { useMutation, UseMutationOptions } from "react-query";
import axios, { AxiosError } from "axios";
import request from "common/request";

type downloadVariable = {
  folderName?: string;
  ids: number[];
};

// export const useGetDownloadPhotoByUserId = (options?: any) => {
//   console.log("options", options);
//   let ids = options as number[];

//   return useQuery<any, AxiosError, downloadVariable>(
//     ["ids"],
//     async () => {
//       const { data } = await request.get<any>(`/download/user`, {
//         params: {
//           ids: ids,
//         },
//       });
//       return data;
//     },
//     {
//       cacheTime: 30 * 1000,
//       ...options,
//     }
//   );
// };

// export const useGetDownloadPhotoByUserId = (
//   options?: UseMutationOptions<any, AxiosError, downloadVariable>
// ) => {
//   return useMutation<any, AxiosError, downloadVariable>(
//     async ({ ids }) => {
//       try {
//         console.log("ff", ids);
//         const response = await request.post(`/download/user`, {
//           ids,
//         });
//         return response;
//       } catch (e) {
//         alert("There was an error. Please try again later.");
//       }
//     },
//     {
//       ...options,
//     }
//   );
// };

// export default useGetDownloadPhotoByUserId;

export const useGetDownloadPhotoByUserId = (
  options?: UseMutationOptions<any, AxiosError, downloadVariable>
) => {
  return useMutation<any, AxiosError, downloadVariable>(
    async ({ ids, folderName,  }) => {
      try {
        console.log("ff", folderName);
        const response = await request.post(`/make`,
        {
          ids,
          folderName,
        },
        );
        return response;
      } catch (e) {
        //@ts-ignore
        return e.response
      }
    },
    {
      ...options,
    }
  );
};

export default useGetDownloadPhotoByUserId;