import styled from "@emotion/styled";
import Page from "components/layout/Page";
import DataTable from "../DataTable";
import { changePageName } from 'hooks/util/usePageName';
import { useEffect, useState } from "react";

const LMS: React.FC<any> = ({ children }) => {
  const [ loading, setLoading ] = useState(false);

  const onRefresh = () => {
    setLoading(true);
    // refetchGetUserData();
    window.location.reload()
    setLoading(false);
  };

  useEffect(() => {
    const pageName = 'LMS-Order';
    changePageName(pageName);
  },[]);

  return (
    <>
      <Page>
        <LMSWrapper>
          <RenderingBox>
            <RenderingButton onClick={onRefresh}>
              <img src="/images/Ellipse 16.svg" />
              <ButtonText> &nbsp; refresh</ButtonText>
            </RenderingButton>
          </RenderingBox>
          <TableContaier>
            <DataTable/>
          </TableContaier>
        </LMSWrapper>
      </Page>
    </>
  )
}

export default LMS;

const LMSWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const RenderingBox = styled.div`
  width: 100%;
  /* height: 15px; */
  margin-bottom: 20px;
`;

const RenderingButton = styled.button`
  float: right;
  margin-left: auto;
  background-color: white;
  border: 0;
  display: flex;
  flex-direction: row;
`;

const ButtonText = styled.span`
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  color: #e6e6e6;
  :hover {
    color: black;
  }
`;

const TableContaier = styled.div`
  width: 100%;
  /* height: 300px; */
  min-height: 60vh;
  display: flex;
  flex-direction: column;
`;