import React, { useCallback, useEffect, useMemo, useState, Fragment, } from "react";
import { 
  useTable, 
  useRowSelect, useGlobalFilter, useSortBy, useFilters, usePagination, useExpanded } from "react-table";
import styled from "@emotion/styled";
import { matchSorter } from "match-sorter";
import { Filter, DefaultColumnFilter } from "../Table/Filter";
import { Row, Col, Button } from "reactstrap";
import { renderRowSubComponent } from "./RowRender"

interface ITable {
  columnData: any;
  bodyData: any;
  props?: any;
  filters?: any;
  filter?: any;
  renderRowSubComponent?: any;
  hiddenColumn? : any;
}

const Table: React.FC<ITable> = ({ columnData, bodyData, filters, filter, hiddenColumn }) => {
  const [IdLIst, setIdList] = useState([]);

  const data = useMemo(() => bodyData, [bodyData]);
  const columns = useMemo(() => columnData, []);


  useEffect(() => {
    let ids: any = [];
    data.map((item: any, i: number) => {
      ids[i] = item;
    });
    setIdList(ids);
  }, [data]);



  const ourGlobalFilter = useCallback(
    (rows: any, ids: any, query: string) => {
      return matchSorter(rows, query, {
        keys: filters.map((columnName: string) => `values.${columnName}`),
      });
    },
    [filters]
  );

  const onChangeInSelect = (e: any) => {
    e.stopPropagation();
    setPageSize(Number(e.target.value));
  };

  // const onChangeInInput = (e: any) => {
  //   const page = e.target.value ? Number(e.target.value) - 1 : 0;
  //   gotoPage(page);
  // };

  const {
    //@ts-ignore
    setGlobalFilter,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    //@ts-ignore
    page,
    prepareRow,
    visibleColumns,
    //@ts-ignore
    canPreviousPage,
    //@ts-ignore
    canNextPage,
    //@ts-ignore
    pageOptions,
    //@ts-ignore
    pageCount,
    //@ts-ignore
    gotoPage,
    //@ts-ignore
    nextPage,
    //@ts-ignore
    previousPage,
    //@ts-ignore
    setPageSize,
    //@ts-ignore
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      //@ts-ignore
      globalFilter: ourGlobalFilter,
      //@ts-ignore
      defaultColumn: { Filter: DefaultColumnFilter },
      //@ts-ignore
      initialState: { pageIndex: 0, pageSize: 10, hiddenColumns: hiddenColumn},
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
  );

  useEffect(() => {
    setGlobalFilter(filter);
    //@ts-ignore
  }, [filter, setGlobalFilter]);

  return (
    <>
      <TableContainer {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Tableheadth
                  //@ts-ignore
                  {...column.getHeaderProps()}
                  style={{
                    borderBottom: "solid 3px #DFDFDF",
                    borderTop: "solid 3px #DFDFDF",
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  <div
                    //@ts-ignore
                    {...column.getSortByToggleProps()}
                  >
                    {column.render("Header")}
                    {generateSortingIndicator(column)}
                  </div>
                  <Filter column={column} />
                </Tableheadth>
              ))}
            </tr>
          ))}
        </TableHead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row: any) => {
            prepareRow(row);
            return (
              <Fragment key={row.getRowProps().key}>
                <tr>
                {row.cells.map((cell: any) => {
                  return (
                    <BodyTd
                      {
                        //@ts-ignore
                        ...cell.getCellProps({
                          style: {
                            // @ts-ignore
                            width: cell.column.width,
                            padding: "10px",
                            justifyContent: "center",
                            
                          },
                        })
                      }
                      style={{
                        padding: "10px",
                        justifyContent: "center",
                      }}
                    >
                      {
                        //@ts-ignore
                        cell.render("Cell")
                      }
                    </BodyTd>
                  );
                })}
              </tr>
              {row.isExpanded && (
                <tr>
                  <td colSpan={visibleColumns.length}>
                    {renderRowSubComponent(row)}
                    </td>
                </tr>
              )}
            </Fragment>
            );
          })}
        </Tbody>
      </TableContainer>

      <PageNation>
        <Row
          style={{
            maxWidth: 3000,
            margin: "0 auto",
            alignItems: "center",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
          }}
        >
          <Col md={3}>
            <Button
              color="primary"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
              style={{
                marginRight: "10px",
              }}
            >
              {"<<"}
            </Button>
            <Button
              color="primary"
              onClick={previousPage}
              disabled={!canPreviousPage}
              style={{
                marginRight: "10px",
              }}
            >
              {"<"}
            </Button>
          </Col>
          <Col md={2} style={{ marginTop: 7, marginRight: 10 }}>
            {"  "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </Col>
          <Col md={2}>
            <select value={pageSize} onChange={onChangeInSelect}>
              {[10, 20].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  show {pageSize}
                </option>
              ))}
            </select>
          </Col>
          <Col md={3}>
            <Button
              color="primary"
              onClick={nextPage}
              disabled={!canNextPage}
              style={{
                marginLeft: "10px",
              }}
            >
              {">"}
            </Button>
            <Button
              color="primary"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
              style={{
                marginLeft: "10px",
              }}
            >
              {">>"}
            </Button>
          </Col>
        </Row>
      </PageNation>
    </>
  );
};

export default Table;

const TableContainer = styled.table`
  width: 100%;
  font-weight: 500;
  font-size: 11px;
`;

const Tbody = styled.tbody`
  text-align: center;
`;

const PageNation = styled.div`
  display: flex;
  flex-direction: row;
`;

const generateSortingIndicator = (column: any) => {
  return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : "";
};

const BodyTd = styled.td`
  font-size: 11px;
  font-weight: 500;
  border-bottom: solid #e5e5e5 ;
`

const TableHead = styled.thead`
  background-color: #e5e5e5;
  height: 60px;
`
const Tableheadth = styled.th`
  padding-top: 10px;
  font-size: 13px;
`
