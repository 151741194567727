import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import FilterContainer from "./order/FilterContainer";
import TopButtonContainer from './order/TopContainer';
import DateRangePicker from 'components/ui/PIMS/DatePicker';
import { useGetOrderDataByFilter } from 'hooks/api';

import AllOrders from './order/Tap/AllOrders';
import PreOrders from './order/Tap/PreOrders';

interface FilterOptionsProps {
  userTier?      : number,
  isDownload?    : number,
  deliveryStage? : number,
  orderType     : number;
  year?         :   null | number;
  month?        :  null | number;
}

const Elem: React.FC<any> = () => {
  const [ filter, setFilter ] = useState("");
  const [ checkList, setCheckList ] = useState([{}]);
  const [ filterOptions, setFilterOptions ] = useState<FilterOptionsProps>({
    userTier      : 1,
    isDownload    : 1,
    deliveryStage : 1,
    orderType     : 1,
    year          : 0,
    month         : 0,
  })

  const [tab, setTab] = useState(1);
  // const { data } = useGetOrderDataByFilter(filterOptions, tab);
  const { refetch: refetchGetOrdersByFilter } = useGetOrderDataByFilter(filterOptions);

  const handleInputChange = async (e: any) => {
    const { value } = e.currentTarget;
    setFilter(value);
  };

  const getCheckList = async (e: any) => {
    setCheckList(e);
  };

  const getFilterInfo = async (e:any) => {
    setFilterOptions((preFilterOptions) =>({
      ...preFilterOptions,
      userTier      : e?.userTier,
      isDownload    : e?.isDownload,
      deliveryStage : e?.deliveryStage,
      orderType     : e?.orderType,
      year          : e?.year,
      month         : e?.month
    }))
  };

  const getDateRange = async (e:any) => {
    setFilterOptions((preFilterOptions) =>({
      ...preFilterOptions,
      year : e?.year,
      month: e?.month
    }))
  };

  const handleSearchInFilterOptions = async () => {
    await refetchGetOrdersByFilter();
  }

  const defaultTab = 1;
  return (
    <>
      <Topwrapper>
        <FilterInput
          value={filter}
          onChange={handleInputChange}
          placeholder="이름, 전화번호"
        />
        <TopButtonContainer checkList={checkList} tab={defaultTab} />
      </Topwrapper>
      <FilterWrapper>
        <FilterContainer getFilterInfo={getFilterInfo} /> 
        <DayFilterWrapper>
          <DateRangePicker getDateRange={getDateRange} />
        </DayFilterWrapper>
        <ButtonContainer>
          <SearchButton
            onClick={handleSearchInFilterOptions}
          >
            검색
          </SearchButton>
        </ButtonContainer>
      </FilterWrapper>
      <Tap>
        <TapContainer>
          <Menu
            //@ts-ignore
            isSeleted={tab === 1}
            onClick={() => setTab(1)}
          >
            주문목록
          </Menu>
          {/* <Menu
            //@ts-ignore
            isSeleted={tab === 2}
            onClick={() => setTab(2)}
          >
            정기인화(BASIC)
          </Menu>
          <Menu
            //@ts-ignore
            isSeleted={tab === 3}
            onClick={() => setTab(3)}
          >
            무료체험(GIFT)
          </Menu>
          <Menu
            //@ts-ignore
            isSeleted={tab === 4}
            onClick={() => setTab(4)}
          >
            정기인화 미 업로드(NoUpload)
          </Menu> */}
          <Menu
            //@ts-ignore
            isSeleted={tab === 5}
            onClick={() => setTab(5)}
          >
            기존 양식(필터적용 X)
          </Menu>
        </TapContainer>
        {tab === 1 && 
        <AllOrders 
          value={filter}
          getCheckListInLMS={getCheckList}
          tab={tab}
          filterOptions={filterOptions}
        />}
        {tab === 5 && <PreOrders 
          value={filter}
          getCheckListInLMS={getCheckList}
        />}
      </Tap>
    </>
  );
};

export default Elem;
const FilterInput = styled.input`
  border: 1px solid black;
  border-radius: 5px;
  padding: 0.4rem;
  margin-bottom: 10px;
  margin-right: auto;
`;


const Topwrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  margin-left: auto;
`;
const Tap = styled.div`
  width: 100%;
  height: 30px;
`;


const TapContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
const Menu = styled.button`
  width: 250px;
  @media (max-width: 1500px) {
    width: 200px;
  }
  height: 43px;
  top: 157px;
  left: 100px;
  font-size: 12px;
  text-align: center;
  line-height: 43px;
  font-size: 12px;
  cursor: pointer;

  background-color: ${(props) =>
    //@ts-ignore
    props.isSeleted ? "white" : "#DFDFDF"};
  color: ${(props) =>
    //@ts-ignore
    props.isSeleted ? "black" : "white"};
  border: ${(props) =>
    //@ts-ignore
    props.isSeleted ? "1px solid black;" : "white"};
  border-bottom-style: none;
`;
const FilterWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const DayFilterWrapper = styled.div`
  /* margin-left: 10px; */
  width: 200px;
  margin-top: 20px;
  /* background-color: antiquewhite; */
`;
const ButtonContainer = styled.div`
  width: 150px;
  height: 100%;
  /* height: 30px; */
  margin-left: 20px;
`;
const SearchButton = styled.button`
  width: 100%;
  /* height: 100%; */
  height: 30px;
  background-color: white;
  border: 0.1px solid var(--grey);
  color: var(--grey);
  line-height: 20px;
  outline: none;
  border-radius: 5px;
  margin-top: 22px;
`;
