import styled from "@emotion/styled";
import { usePostLogin } from "hooks/api";
import { useState } from "react";
import {  useNavigate } from 'react-router-dom';

const Elem: React.FC<any> = ({ children }) => {
  const { mutateAsync: postLogin } = usePostLogin();
  const [inputId, setInputId] = useState<string>("");
  const [inputpwd, setInputpwd] = useState<string>("");
  // const navigate = useNavigate();

  const login = async () => {
    const result = await postLogin({
      userId: inputId,
      password: inputpwd,
    });

    const token = await result?.data?.accessToken.token;
    console.log('token', token);
    if (token) {
      localStorage.setItem("token", token);
      localStorage.setItem("page", "LMS-Order");
      window.location.href = `/lms/orders`;
    }
  };

  return (
    <LoginContainer>
      <TopContainer>
        <PimsTitleBox>
          <PimsTitle>PIMS</PimsTitle>
        </PimsTitleBox>
        <SubTitleContainer>
          <SubTitleFontColor>P</SubTitleFontColor>hotypeta&nbsp;
          <SubTitleFontColor>I</SubTitleFontColor>ntegrated&nbsp;
          <SubTitleFontColor>M</SubTitleFontColor>anagement&nbsp;
          <SubTitleFontColor>S</SubTitleFontColor>tystem
        </SubTitleContainer>
      </TopContainer>
      <Loginbox className="loginBox">
        <ContextName>Login</ContextName>
        <InputId
          placeholder="ID"
          id="id"
          onChange={(e) => setInputId(e.target.value)}
        ></InputId>
        <InputPw
          type="password"
          placeholder="PW"
          id="password"
          onChange={(e) => setInputpwd(e.target.value)}
        ></InputPw>
        <CheckButton onClick={login}>로그인</CheckButton>
      </Loginbox>
    </LoginContainer>
  );
};

const LoginContainer = styled.div`
  width: 100%;
  height: 782px;
  margin: 100px auto;
  /* margin-left: 100px; */
  display: flex;
  flex-direction: column;
`;

const TopContainer = styled.div`
  width: 100%;
  height: 90px;
  display: flex;
  flex-direction: row;
`;

const PimsTitleBox = styled.div`
  width: 100px;
  height: 50%;
  margin-left: 100px;
  margin-right: auto;
`;

const PimsTitle = styled.p`
  font-weight: 100;
  font-size: 43px;
  margin: 0;
  opacity: 0.3;
`;

const SubTitleContainer = styled.div`
  width: 356px;
  height: 24px;
  margin-left: auto;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: -0.05em;
`;

const SubTitleFontColor = styled.span`
  color: #0e0465;
  font-weight: bold;
`;

const Loginbox = styled.div`
  width: 240px;
  height: 182px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -0%);
`;

const ContextName = styled.p`
  width: 84px;
  height: 35px;
  margin: 0 auto;
  font-size: 32px;
  margin-bottom: 30px;
  font-weight: 100;
  line-height: 108%;
  opacity: 0.3;
`;

const InputId = styled.input`
  height: 50px;
  border-top-style: none;
  border-right-style: none;
  border-left-style: none;
  opacity: 0.5;
  border-bottom: solid #e5e5e5;
`;

const InputPw = styled.input`
  height: 70px;
  border-top-style: none;
  border-right-style: none;
  border-left-style: none;
  border-bottom: solid #e5e5e5;
  opacity: 0.5;
`;

const CheckButton = styled.button`
  margin-top: 20px;
  background-color: white;
  border-style: none;
  text-align: right;
  opacity: 0.3;
  font-size: 20px;

  :hover {
    opacity: 0.1;
  }
`;

export default Elem;
