import styled from '@emotion/styled';
import { useState } from 'react';

interface Option {
  className?:string;
  value: number;
  name: string;
}

const SelectFilter:React.FC<{
  options:Option[],
  onChange: (selectedOption: Option) => void;
}> = ({ options, onChange }) => {
  const [ selectedOption, setSelectedOption ] = useState<number>(1);  

  const handleOptionChange = (event:React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = parseInt(event.target.value);
    const selected = options.find((option) => option.value === selectedValue);

    if (selected) {
      setSelectedOption(selected.value);
      onChange(selected);
    }
  }

  return (
		<DropDownContainer value={selectedOption} onChange={handleOptionChange}>
			{options.map((option: any) => (
				<option
					key={option.value}
					value={option.value}
				>
					{option.name}
				</option>
			))}
		</DropDownContainer>
	);
};

export default SelectFilter;

const DropDownContainer = styled.select`
  width: 100%;
  height: 100%;
  border: solid 1px grey;
  background-color: white;
`
