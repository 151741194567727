import { useMutation, UseMutationOptions } from "react-query";
import axios, { AxiosError } from "axios";
import request from "common/request";

type regulerListVariable = {
  year: number;
  month: number;
  isUpload: number;
  isBook: number;
  isAdditional: number;
  isPay       : number;
  deliveryType: number;
};

export const usePostRegulerList = (
  options?: UseMutationOptions<any, AxiosError, regulerListVariable>
) => {
  return useMutation<any, AxiosError, regulerListVariable>(
    async ({ year, month, isUpload, isBook, isAdditional, isPay, deliveryType  }) => {
      try {
        const response = await request.post(`/lms/reguler/list`,
        {
          year,
          month,
          isUpload,
          isBook,
          isAdditional,
          isPay,
          deliveryType
        }
        );
        console.log(response)
        return response;
      } catch (e) {
        //@ts-ignore
        const response = e.response;
        console.log(response.data)
        if ( response?.data.message === "no Seleted Order Ids") {
          return response;
        } else {
          alert("There was an error. Please try again later.");
        }
      }
    },
    {
      ...options,
    }
  );
};

export default usePostRegulerList;