import styled                                 from "@emotion/styled";
import React, { useEffect, useRef, useState } from "react";
import { useGetAllSubscribeUsers }            from "hooks/api";
import { DownloadButton }                     from "components/ui";
import { CSVLink }                            from "react-csv";
import BackedSubs                             from "./BackedSubsModal";
import { usePostBackedUser }                  from "hooks/api";

const Elem: React.FC<any> = ({ peopleData, checked, data }) => {
  const [ subsData, setSubsData ] = useState([]);
  // const { refetch: refetchGetUserData } = useGetAllSubscribeUsers();
  const [ loading, setLoading ] = useState(false);
  const csvLinkref = useRef<any>(null);
  const [ showModal, setShowModal ] = useState(false);
  const { mutateAsync: postBackedUser } = usePostBackedUser();
  const [ backedData, setBackedData] = useState();

  const onRefresh = () => {
    setLoading(true);
    // refetchGetUserData();
    window.location.reload()
    setLoading(false);
  };
  
  const closeModal = () => {
    if (showModal === true) return setShowModal(false)
  }

  const DownloadExcel = (e: any) => {
    e.preventDefault();
    csvLinkref?.current?.link.click();
  }

  const changeIds = async (checked:any) => {
    let ids: any = [];
    checked.map((item: any, i: number) => {
      ids[i] = item["id"];
    });
    
    if (checked.length !== 0) {
      await getData(ids);
    }
  };

  const getData = async (IdList:number[]) => {
    const result = await postBackedUser({
      ids:IdList
    })

    setBackedData(result.data.backedUserList)
  };

  const handleBackDatedButtonOpenModal= async () => {
    if ( checked.length !== 0){
      await changeIds(checked);
      setShowModal(true);
    } else {
      alert("선택된 구독자가 없습니다.")
    }
  };
  
  const today = new Date();

  const CSV_HEADERS = [
    { label: "수령자 연락처", key: "mobile" },
    { label: "이름", key: "name" },
  ];
  
  const getFileName = () => {
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const day = today.getDay();

    return `${year}-${month}-${day} 알림톡`;
  };
  
  useEffect(() => {
    setSubsData(data?.data)
  },[]);

  return (
    <> 
      <Container>
        <MainContainer>
          <SubscribeDataBlock>
            <SubscribeTypeBox>
              <SubscribeTypeTitleWrap>
                <SubscribeTypeTitle>총 인원수</SubscribeTypeTitle>
              </SubscribeTypeTitleWrap>
              <CountOfUserDataWrap>
                <CountOfUserData>{peopleData.allUsers}</CountOfUserData>
              </CountOfUserDataWrap>
            </SubscribeTypeBox>
            <SubscribeTypeBox>
              <SubscribeTypeTitleWrap>
                <SubscribeTypeTitle>구독자수</SubscribeTypeTitle>
              </SubscribeTypeTitleWrap>
              <CountOfUserDataWrap>
                <CountOfUserData>{peopleData.allSubs}</CountOfUserData>
              </CountOfUserDataWrap>
            </SubscribeTypeBox>
            <SubscribeTypeBox>
              <SubscribeTypeTitleWrap>
                <SubscribeTypeTitle>미구독자</SubscribeTypeTitle>
              </SubscribeTypeTitleWrap>
              <CountOfUserDataWrap>
                <CountOfUserData>{peopleData.unSubs}</CountOfUserData>
              </CountOfUserDataWrap>
            </SubscribeTypeBox>
            <SubscribeTypeBox>
              <SubscribeTypeTitleWrap>
                <SubscribeTypeTitle>탈퇴자수</SubscribeTypeTitle>
              </SubscribeTypeTitleWrap>
              <CountOfUserDataWrap>
                <CountOfUserData>{peopleData.deleteUser}</CountOfUserData>
              </CountOfUserDataWrap>
            </SubscribeTypeBox>
          </SubscribeDataBlock>
        </MainContainer>
        <BottomContainer>
          <ButtonBox>
            <CSVLink
              data={ data?.data || []}
              headers={CSV_HEADERS}
              filename={getFileName()}
              ref={csvLinkref}
              />
            <DownloadButton
              icon="file"
              layoutVariant="outlined"
              variant="default"
              size="tiny"
              onClick={DownloadExcel}
              >알림톡 명단 다운로드</DownloadButton>
            <DownloadButton
              icon="file"
              layoutVariant="outlined"
              variant="default"
              size="tiny"
              onClick={handleBackDatedButtonOpenModal}
              >테스트중</DownloadButton>
          </ButtonBox>
          {showModal && <BackedSubs showModal={showModal} closeModal={closeModal} backedData={backedData} />}
        </BottomContainer>
      </Container>
    </>
  );
};

export default Elem;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const MainContainer = styled.div`
  /* height: 170px; */
  height: 100%;
`;

const SubscribeDataBlock = styled.div`
  width: 440px;
  height: 80%;
  /* margin-left: 300px; */
  margin: 0 auto;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const SubscribeTypeBox = styled.div`
  width: 100px;
  height: 100%;
`;

const SubscribeTypeTitleWrap = styled.div`
  width: 100%;
  height: 30px;
  border-bottom: 1px solid;
`;

const SubscribeTypeTitle = styled.p`
  width: 65px;
  font-weight: bold;
  font-size: 17px;
  margin: 2px auto;
`;
const CountOfUserDataWrap = styled.div`
  width  : 100px;
  height : 30px;
  margin : 10px auto;
  /* background-color: yellow; */
`;
const CountOfUserData = styled.p`
  margin-left: 35px;
  font-size: 20px;
`;

const BottomContainer = styled.div`
   margin-left: auto;
  background-color: azure;
`;

const ButtonBox = styled.div`
  height: 100%;
  width: 300px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* margin-right: 30px; */
`;