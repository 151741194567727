import { useMutation, UseMutationOptions } from "react-query";
import axios, { AxiosError } from "axios";
import request from "common/request";

type CSVUploadVariable = {
  filename?: FormData;
  tab: number;
};

export const usePostCSVFileUpload = (
  options?: UseMutationOptions<any, AxiosError, CSVUploadVariable>
) => {
  return useMutation<any, AxiosError, CSVUploadVariable>(
    async ({ filename, tab }) => {
      try {
        const response = await request.post(`csv/upload/${tab}`,filename,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          },
        }
        );
        
        return response;
      } catch (e) {
        //@ts-ignore
        const response = e.response;
        console.log(response.data)
        console.log(response)
        if ( response?.data.message === "no Seleted Order Ids") {
          return response;
        } else {
        
          alert(`${response?.data.message}`);
        }
      }
    },
    {
      ...options,
    }
  );
};

export default usePostCSVFileUpload;