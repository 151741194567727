import React from "react";
import styled from "@emotion/styled";
import ModalTable from "./ModalTable"


const handleSubsCouponData = ({ value}: any) => {
  if (value === false) {
    return "미사용";
  } else {
    return "적용중";
  }
}

const Elem: React.FC<any> = ({ data }) => {
  const columnData = [
    {
      accessor: "id",
      Header: "DBNumber",
      id: "id",
      // disableSortBy: true,
      disableFilters: true,
      disableCheckbox: true,
    },
    {
      accessor: "photyId",
      Header: "회원번호",
      id: "photyId",
      disableFilters: true,
    },
    {
      accessor: "name",
      Header: "고객명",
      id: "name",
      disableSortBy: true,
      disableFilters: true,
    },
    {
      accessor: "isSubsCoupon",
      Header: "구독권 사용여부",
      id: "isSubsCoupon",
      disableSortBy: true,
      // Filter: SelectColumnFilter,
      // filter: "equals",
      disableFilters: true,
      Cell:handleSubsCouponData
    },
    {
      accessor: "preAmount",
      Header: "현재 구독료",
      id: "preAmount",
      disableFilters: true,
    },
    {
      accessor: "afterAmount",
      Header: "적용후 구독료",
      id: "afterAmount",
      disableFilters: true,
      
    },
  ];
  const hiddenColumn = ['id'];
  
  return (
    <Container>
      <ModalTable
        columnData={columnData}
        bodyData={data}
        hiddenColumn={hiddenColumn}
      />
    </Container>
  );
};

export default Elem;

const Container = styled.div`
  width: 100%;
  height: 100%;
`;