import React, { useState, useEffect } from "react";
import Page from "components/layout/Page";
import styled from "@emotion/styled";
import { UncheckedTable } from "components/ui";
import { useGetZipList } from "hooks/api";
import { DownloadButton } from "components/ui";
import { useDeleteZipList  } from "hooks/api";
import LoadingOverlay from 'components/ui/PIMS/LoadingOverlay';
import { changePageName } from 'hooks/util/usePageName';


const changeDateFormatCell = ({ value }: any) => {
  const date = new Date(value);
  const createdAt = date.toLocaleDateString()
  return createdAt;
};

const onClickZipDownload = (value:any) => {
  const link = document.createElement('a');
  const downloadUrl = value;
  link.href = downloadUrl;    
  
  link.setAttribute('download', downloadUrl);
  document.body.appendChild(link)
    
  link.click();
    
  link?.parentNode?.removeChild(link);

  return downloadUrl

}

const zipDownloadCell = ({ value }: any) => {
  return (
  <button 
    onClick={() => onClickZipDownload(value)}
  >download</button>
  )
}

const Elem: React.FC<any> = ({ children }) => {
  const { mutateAsync: zipDelete } = useDeleteZipList();
  const { data } = useGetZipList();
  const { refetch:refetchGetZiplist } = useGetZipList();
  const [ loading, setLoading ] = useState(false);
  
  const onClickAllDeleteZip = async () => {
    setLoading(true)
    const response = await zipDelete({});
    const statusCode = response.status;

    if( statusCode === 200) {
      setLoading(false);
      refetchGetZiplist();
    } else {
      alert (response.data.message)
    } 
  }

  const columnData = [
    {
      accessor: "url",
      Header: () => null,
      id: "url",
      // disableSortBy: true,
      disableFilters: true,
      disableCheckbox: true,
      Cell: zipDownloadCell
    },
    {
      accessor: "id",
      Header: "DBNumber",
      id: "id",
      // disableSortBy: true,
      disableFilters: true,
      disableCheckbox: true,
    },
    {
      accessor: "createdAt",
      Header: "생성일자",
      id: "createdAt",
      // disableSortBy: true,
      disableFilters: true,
      disableCheckbox: true,
      Cell: changeDateFormatCell
    },

    {
      accessor: "fileName",
      Header: "압축 파일 이름",
      id: "ZipName",
      // disableSortBy: true,
      disableFilters: true,
      disableCheckbox: true,
    },
    
  ];


  useEffect(() => {
    const pageName = 'LMS-Download';
    changePageName(pageName);
  },[]);

  return (
    <>
      <Page>
        <DownalodPageWrapper>
          <DownloadButtonWrapper>
            <AllZipDeleteButtonWrapper>
              <DownloadButton
                icon="file"
                layoutVariant="contained"
                variant="default"
                size="tiny"
                onClick={onClickAllDeleteZip}
                >
                파일 전체 삭제
              </DownloadButton>
            </AllZipDeleteButtonWrapper>
          </DownloadButtonWrapper>
          <UncheckedTable
            columnData={columnData}
            bodyData={data || []}
            filters={["name"]}
            hiddenColumn={[]}
          />
        </DownalodPageWrapper>
        < LoadingOverlay loading={loading}/>
      </Page>
    </>
  );
};

export default Elem;

const DownalodPageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const DownloadButtonWrapper = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  margin-bottom: 10px;
`
const AllZipDeleteButtonWrapper = styled.div`
  width: 200px;
  height: 40px;
  position: relative;
  top: 50%;
  margin-left: auto;
  margin-right: 40px;
`
