import styled from '@emotion/styled';
import React, { useState } from "react"
import RegulerDeliveryTable from "./DeliveryTable";
import AdditionalDeliveryTable from "./AdiitionalDelivery/AdditionalDeliveryTable";

const SelectedTap:React.FC<any> = () => {
  const [ filter, setFilter ] = useState("");
  const [tab, setTab] = useState(1);
  return (
    <Tap>
      <TapContainer>
        <Menu
          //@ts-ignore
          isSeleted={tab === 1}
          onClick={() => setTab(1)}
        >
          정기배송
        </Menu>
        <Menu
          //@ts-ignore
          isSeleted={tab === 2}
          onClick={() => setTab(2)}
        >
          추가 배송
        </Menu>
      </TapContainer>
      {tab === 1 && 
        <RegulerDeliveryTable 
          tab={tab}
        />}
      {tab === 2 && 
        <AdditionalDeliveryTable 
          tab={tab}
      />}
    </Tap>
  )
};

export default SelectedTap;

const Tap = styled.div`
  width: 100%;
  height: 30px;
`;

const TapContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Menu = styled.button`
  width: 250px;
  @media (max-width: 1500px) {
    width: 200px;
  }
  height: 43px;
  top: 157px;
  left: 100px;
  font-size: 12px;
  text-align: center;
  line-height: 43px;
  font-size: 12px;
  cursor: pointer;

  background-color: ${(props) =>
    //@ts-ignore
    props.isSeleted ? "white" : "#DFDFDF"};
  color: ${(props) =>
    //@ts-ignore
    props.isSeleted ? "black" : "white"};
  border: ${(props) =>
    //@ts-ignore
    props.isSeleted ? "1px solid black;" : "white"};
  border-bottom-style: none;
`;