import {
  Container,
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle
} from 'reactstrap';
import styled from "@emotion/styled";

export const renderRowSubComponent = (row:any) => {
  const photoUrls = row.original.photos;

  return (
    <ExpandContainer>
      <ThumbnailContainer>
        <ThumbnailWrapper>
          <ThumnailImg src={photoUrls[0]}/>
        </ThumbnailWrapper>
        <ThumbnailWrapper>
          <ThumnailImg src={photoUrls[1]}/>
        </ThumbnailWrapper>
        <ThumbnailWrapper>
          <ThumnailImg src={photoUrls[2]}/>
        </ThumbnailWrapper>
        <ThumbnailWrapper>
          <ThumnailImg src={photoUrls[3]}/>
        </ThumbnailWrapper>
        <ThumbnailWrapper>
          <ThumnailImg src={photoUrls[4]}/>
        </ThumbnailWrapper>
      </ThumbnailContainer>
    </ExpandContainer>
  )
}

const ExpandContainer = styled.div`
  width: 100%;
  height: 300px;
`
const ThumbnailContainer = styled.div`
  width: 80%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const ThumbnailWrapper = styled.div`
  width: 15%;
  height: 100%;
`

const ThumnailImg = styled.img`
  width: 100%;
  height: 100%;
`