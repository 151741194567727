import { createGlobalStyle } from '@xstyled/emotion';

import colors from './colors';
import typography from './typography';

import reset from './reset';

export default createGlobalStyle`
  ${reset}
  ${colors}
  ${typography}
  
    body.body-lock {
    overflow: hidden;
  }
`;
