import React from "react";
import styled from "@emotion/styled";
import { Table } from "components/ui";
import { CheckOutlined } from "@ant-design/icons";
import { useGetAllSubsCouponUsers } from 'hooks/api';

const isUseCell = ({ value }: any) => {
  if (value === false) {
    return <NoUploadText>사용완료</NoUploadText>
  } else {
    return <UplodadText>사용중</UplodadText>
  }
}

const UserSubsCoupon: React.FC<any> = ({ getCheckListInLMS=[], value,  tab, filterOptions }) => {  
  const { data } = useGetAllSubsCouponUsers();
  const columnData = [
    {
      accessor: "id",
      Header: "DBNumber",
      id: "id",
      // disableSortBy: true,
      disableFilters: true,
      disableCheckbox: true,
    },
    {
      accessor: "user[1]",
      Header: "회원번호",
      id: "photyId",
      disableFilters: true,
    },
    {
      accessor: "user[0]",
      Header: "고객명",
      id: "name",
      disableSortBy: true,
      disableFilters: true,
    },
    {
      accessor: "phone",
      Header: "전화번호",
      id: "phone",
      disableSortBy: true,
      disableFilters: true,
    },
    {
      accessor: "couponType",
      Header: "타입",
      id: "couponType",
      disableSortBy: true,
      // Filter: SelectColumnFilter,
      // filter: "equals",
      disableFilters: true,
    },
    {
      accessor: "commerce",
      Header: "구매처",
      id: "commerce",
      disableFilters: true,
    },
    {
      accessor: "subsMonth",
      Header: "개월/횟수",
      id: "month",
      disableFilters: true,
    },
    {
      accessor: "coupon[2]",
      Header: "쿠폰 번호",
      id: "couponNumber",
      disableSortBy: true,
      disableFilters: true,
    },


    {
      accessor: "startAt",
      Header: "사용 날짜",
      id: "startDate",
      disableSortBy: true,
      disableFilters: true,
    },

    {
      accessor: `finalDate`,
      Header: "만료 예정 날짜",
      id: "expectedExpirationDate",
      disableSortBy: true,
      disableFilters: true,
    },
    {
      accessor: `finalAt`,
      Header: "만료 날짜",
      id: "expriationDate",
      disableSortBy: true,
      disableFilters: true,
    },
    {
      accessor: "isUse",
      Header: "현재 상태",
      id: "isUse",
      disableSortBy: true,
      disableFilters: true,
      Cell: isUseCell
    },
  ];

  const hiddenColumn = ["id"];

  const getCheckList = async (e: any) => {
    getCheckListInLMS(e);
  };
  
  return (
    <Container>
      <Table
        columnData={columnData}
        bodyData={data || []}
        getCheckList={getCheckList}
        filters={["couponNumber", "name"]}
        filter={value}
        hiddenColumn={hiddenColumn}
      />
    </Container>
  );
};

export default UserSubsCoupon;

const Container = styled.div`
  margin-top: 10px;
  width: 100%;
`;
const UplodadText = styled.p`
  font-size: 12px;
  color: green;
`;
const NoUploadText = styled.p`
  font-size: 12px;
  font-weight: bold;
  color: red;
`;