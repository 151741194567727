import styled from "@emotion/styled";
import React from 'react';
import Page from "../../layout/Page";

const DAMS:React.FC<any> = () => {
  return (
    <Page>
      <h1>DAMS</h1>
    </Page>
  )
}

export default DAMS