import styled from '@emotion/styled';
import { ChangeEvent, useEffect, useState } from 'react';
import { usePostCSVFileUpload } from 'hooks/api';
import { useGetOrderDataByFilter } from 'hooks/api';
import LoadingOverlay from 'components/ui/PIMS/LoadingOverlay';

interface FilterOptionsProps {
  userTier?      : number,
  isDownload?    : number,
  deliveryStage? : number,
  orderType?     : number,
  year?    :  null | number;
  month?    :  null | number;
}

const filterOptions:FilterOptionsProps = {
  userTier      : 1,
  isDownload    : 1,
  deliveryStage : 1,
  orderType     : 1,
  year          : null,
  month         : null
}

const Elem:React.FC<any> = ({ tab }) => {
  const { mutateAsync: postCSVFileUpload } = usePostCSVFileUpload();
  const [ loading, setLoading ] = useState(false);
  const { refetch: refetchOrderData } = useGetOrderDataByFilter(filterOptions);

  const handleFileUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    //@ts-ignore
    const file = event.target.files[0];
    const formData = new FormData();
    //@ts-ignore
    formData.append('csvFile', file);

    try {
      const response = await postCSVFileUpload({
        filename: formData,
        tab: tab
      })
    } catch (error) {
      alert('File upload Error');
    }
    refetchOrderData();
    console.log('upload Complete');
    setLoading(false);
  };

  return (
    <Container>
      <FileInputLabel>
        송장 파일 업로드
        <FileInput type="file" accept='.csv' onChange={handleFileUpload} key={Date.now()}/>
      </FileInputLabel>
      < LoadingOverlay loading={loading}/>
    </Container>
  )
}

export default Elem;

const Container = styled.div`
  width: 120px;
  height: 33px;
  margin-left: -10px;
`;

const FileInput = styled.input`
  display: none;
`;

const FileInputLabel = styled.label`
  cursor: pointer;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 0.1px solid var(--grey);
  color: var(--grey);
  border-radius: 5px;
  font-size: 10px;
        line-height: 16px;
`;

const UploadButton = styled.button`
  cursor: pointer;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  margin-top: 10px;
`;
