import React, { useMemo } from "react";
import styled from "@emotion/styled";
import { Table } from "components/ui";
import { SelectColumnFilter } from "components/ui/Table/Filter";

const isUpdateCell = ({ value }: any) => {
  if (value === 'upload') {
    return <UplodadText>업로드 완료</UplodadText>
  } else if (value === 'noUpload') {
    return <RedText>미업로드</RedText>
  } else {
    return <ErrorText>Error</ErrorText>
  }
};

const isBook = ({ value}: any) => {
  if (value === true) {
    return <UplodadText>O</UplodadText>
  } else {
    return <RedText>X</RedText>
  }
}

const zipCode = ({ value }: any) => {
  return `${value?.slice(1)}`;
};

const isPay = ({ value}: any) => {
  if (value === false ) {
    return <RedText>미결제</RedText>;
  } 
  return <ChangeBlackText>결제완료</ChangeBlackText>;
};

const isSubsCoupon = ({ value}: any) => {
  if (value === true ) {
    return <ChangeBlackText>사용중</ChangeBlackText>;
  } 
  return <ChangeBlackText>미사용</ChangeBlackText>;
};



const AllOrders: React.FC<any> = ({ getCheckListInLMS, value, data }) => {  
  const columnData = [
    {
      accessor: "id",
      Header: "DBNumber",
      id: "id",
      // disableSortBy: true,
      disableFilters: true,
      disableCheckbox: true,
    },
    {
      accessor: "name",
      Header: "고객명",
      id: "name",
      disableSortBy: true,
      disableFilters: true,
    },
    {
      accessor: "phone",
      Header: "전화번호",
      id: "phone",
      disableSortBy: true,
      disableFilters: true,
    },
    {
      accessor: "invoice",
      Header: "송장 번호",
      id: "invoice",
      disableSortBy: true,
      disableFilters: true,
    },
    {
      accessor: "year",
      Header: "연도",
      id: "year",
      disableSortBy: true,
      disableFilters: true,
    },
    {
      accessor: "month",
      Header: "월",
      id: "month",
      disableSortBy: true,
      disableFilters: true,
    },
    {
      accessor: "postalNumber",
      Header: "우편번호",
      id: "postalNumber",
      disableSortBy: true,
      disableFilters: true,
      Cell: zipCode,
    },
    {
      accessor: `defaultAddress`,
      Header: "주소",
      id: "defaultAddress",
      disableSortBy: true,
      disableFilters: true,
    },
    {
      accessor: `detailAddress`,
      Header: "상세 주소",
      id: "detailAddress",
      disableSortBy: true,
      disableFilters: true,
    },
    {
      accessor: `photyId`,
      Header: "신청자 회원번호",
      id: "photyId",
      disableSortBy: true,
      disableFilters: true,
    },
    {
      accessor: `subscriber`,
      Header: "신청자",
      id: "subscriber",
      disableSortBy: true,
      disableFilters: true,
    },
  ];

  const hiddenColumn = [
    'id', ];

  const getCheckList = async (e: any) => {
    getCheckListInLMS(e);
  };
  
  return (
    <Container>
      <Table
        columnData={columnData}
        bodyData={data || []}
        getCheckList={getCheckList}
        filters={["name", "phone"]}
        filter={value}
        hiddenColumn={hiddenColumn}
      />
    </Container>
  );
};

export default AllOrders;

const Container = styled.div`
  margin-top: 10px;
  width: 100%;
`;
const UplodadText = styled.p`
  font-size: 12px;
  color: green;
`;
const RedText = styled.p`
  font-size: 12px;
  font-weight: bold;
  color: red;
`;
const ErrorText = styled.p`
  font-size: 12px;
  font-weight: bold;
  color: yellow;
`;
const ChangeBlackText = styled.p`
  font-size: 12px;
  font-weight: bold;
  color: Black;
`;