import { useEffect, useState } from 'react';
import SelectFilter  from "components/ui/Select";

import styled from "@emotion/styled";

interface Option {
  className?:string;
  value: number;
  name: string;
}

const userTier:Option[] = [
  { value: 1, name: "전체" },
  { value: 2, name: "회원" },
  { value: 3, name: "체험단" }
]; //원래는 select 태그 안에 들어가는 애들을 배열로 만들어준다.

const isDownLoad:Option[]= [
  { value: 1, name: "전체" },
  { value: 2, name: "미다운" },
  { value: 3, name: "다운로드 완료" },
]

const deliveryStage:Option[] = [
  { value: 1, name: "전체" },
  { value: 2, name: "업로드중" },
  { value: 3, name: "주문 마감" },
  { value: 4, name: "발송 완료" },
];

const orderType:Option[] = [
  { value: 1, name: "전체"},
  { value: 2, name: "정기배송"},
  { value: 3, name: "gift"},
  { value: 4, name: "noUplad"}
]

interface FileterInfo {
  userTier      : number;
  isDownload    : number;
  deliveryStage : number;
  orderType     : number;
}

const Elem:React.FC<any> = ({ getFilterInfo }) => {
  const [ fileterOptions, setFilterOptions ] = useState<FileterInfo>({
    userTier      : 1,
    isDownload    : 1,
    deliveryStage : 1,
    orderType     : 1,
  })

  const handleUserTier = async ( selectedOption:Option ) => {
    setFilterOptions((preSelectedOptions) => ({
      ...preSelectedOptions,
      userTier: selectedOption?.value
    }))
  };

  const handleIsDownload = async ( selectedOption:Option ) => {
    setFilterOptions((preSelectedOptions) => ({
      ...preSelectedOptions,
      isDownload: selectedOption?.value
    }))
  };

  const handleIsDeliveryStage = async ( selectedOption:Option ) => {
    setFilterOptions((preSelectedOptions) => ({
      ...preSelectedOptions,
      deliveryStage: selectedOption?.value
    }))
  };

  const handleIsOrderType = async ( selectedOption:Option ) => {
    setFilterOptions((preSelectedOptions) => ({
      ...preSelectedOptions,
      orderType: selectedOption?.value
    }))
  };

  useEffect(() => {
    if (fileterOptions) {
      getFilterInfo(fileterOptions)
    }
  },[fileterOptions])

  return (
    <FilterContainer>
      <FilterWrapper>
        <DropDownContainer>
          <FilterName>회원 등급</FilterName>
          <DropDownWrapper>
            <SelectFilter
              options={userTier}
              onChange={handleUserTier}
            />
          </DropDownWrapper>
        </DropDownContainer>
        <DropDownContainer>
          <FilterName>다운로드 여부</FilterName>
          <DropDownWrapper>
            <SelectFilter
              options={isDownLoad}
              onChange={handleIsDownload}
            />
          </DropDownWrapper>
        </DropDownContainer>
        <DropDownContainer>
          <FilterName>배송 상태</FilterName>
          <DropDownWrapper>
            <SelectFilter 
              options={deliveryStage}
              onChange={handleIsDeliveryStage}
            />
          </DropDownWrapper>
        </DropDownContainer><DropDownContainer className="last">
          <FilterName>주문 타입</FilterName>
          <DropDownWrapper>
            <SelectFilter 
              options={orderType}
              onChange={handleIsOrderType}
            />
          </DropDownWrapper>
        </DropDownContainer>
      </FilterWrapper>
    </FilterContainer>
  )
};

export default Elem;

const FilterContainer = styled.div`
  /* padding: 10px; */
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  /* width: 300px; */
  height: 70px;
  display: flex;
  flex-direction: row;
`;

const FilterWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const DropDownContainer = styled.div`
  width: 150px;
  /* height: 80px; */
  margin-right: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.last {
    margin-right: 0;
  }
`;
const FilterName = styled.div`
  height: 20px;
  font-size: 15px;
  /* padding-top: 4px; */
  color: black;
  font-style: normal;
  font-weight: 500;
`;
const DropDownWrapper = styled.div`
  width: 100%;
  height: 30px;
`;


