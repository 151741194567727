import { AxiosError } from 'axios';
import request from 'common/request';
import { useQuery } from 'react-query';

const useGetLocationList = (options?: any) => {
  return useQuery<any, AxiosError>(
    ['locationList'],
    async () => {
      const { data } = await request.get<any>(`/lms`);
      return data?.data;
    },
    {
      cacheTime: 30 * 1000,
      ...options,
    }
  );
};

export default useGetLocationList;
