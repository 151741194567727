import styled from '@emotion/styled';
import { useState, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import YearMonthPicker from "components/ui/PIMS/YearMonthPicker";

interface SelectedDateInfo {
  year: number;
  month: number;
};

const DateRangePicker:React.FC<any> = ({ getDateRange }) => {
  const [ selectedDate, setSelectedDate ] = useState<SelectedDateInfo>({
    year: 0,
    month: 0
  });

  const getYearMonth = async (e:any) => {
    if (e) {
      const selectedDate = new Date(e);
      const selectedYear = selectedDate.getFullYear();
      const selectedMonth = selectedDate.getMonth() + 1
      setSelectedDate((preSeletecdOptions) => ({
        ...preSeletecdOptions,
        year: selectedYear,
        month: selectedMonth
      }))
    } 
  }

  useEffect(() => {
    if (selectedDate) {
      getDateRange(selectedDate)
    }
  },[selectedDate])

  return (
    <Container>
      <DatePickerContainer>
        <YearMonthPicker getYearMonth={getYearMonth} />
      </DatePickerContainer>
    </Container>
  );
};

export default DateRangePicker;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
`;
const DatePickerContainer = styled.div`
  width: 220px;
  display: flex;
  flex-direction: column;
`;