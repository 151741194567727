import axios, { AxiosError } from "axios";
import request from "common/request";
import { useQuery } from "react-query";

const useGetUserData = (options?: any) => {
  return useQuery<any, AxiosError>(
    ["userAlldata"],

    async () => {
      const { data } = await request.get<any>(`/all/orders`);
      return data?.data;
    },
    {
      cacheTime: 30 * 1000,
      ...options,
    }
  );
};

export default useGetUserData;
