import { AxiosError } from 'axios';
import request from 'common/request';
import { useQuery } from 'react-query';

const useGetUsingCouponList = (options?: any) => {
  return useQuery<any, AxiosError>(
    ['usingCouponList'],
    async () => {
      const { data } = await request.get<any>(`/coupon/general/use`);
      console.log('??????data',data);
      return data;
    },
    {
      cacheTime: 30 * 1000,
      ...options,
    }
  );
};

export default useGetUsingCouponList;
