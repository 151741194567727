import React from "react";
// import Layout from "components/layout/Layout";
import "./App.css";
import { Route, Routes, Navigate } from "react-router-dom";

import NotFound from "components/pages/404/404";
import Payhistory from "components/pages/PMS/payhistory/payhistory";
import Dams from "components/pages/DAMS/Dams";
import Users from "components/pages/UIMS/users/Users"
import Coupon from "components/pages/UIMS/coupons/coupon";
import Login from "components/pages/Login/Login";
import OrderPage from "components/pages/LMS/order/OrderPage";
import Download from 'components/pages/LMS/download/Download';
import Reguler from "components/pages/LMS/reguler/Reguler";

function App() {
  // const loginStatus = "LOGGED_IN";
  const loginStatus = localStorage.getItem("token") ? "LOGGED_IN" : null;

  if (loginStatus === "LOGGED_IN") {
    return <AuthorizedView />;
  }

  return <UnAuthorizedView />;
}

const UnAuthorizedView = () => {
  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
};

const AuthorizedView = () => {
  return (
      <Routes>
        <Route path="/404" element={<NotFound />} />
        <Route path="/" element={<Navigate to="/lms/orders" />} />
        <Route path="/lms/orders" element={<OrderPage />} />
        <Route path="/lms/download" element={<Download />} />
        <Route path="/lms/Reguler" element={<Reguler />} />
        <Route path="/pms/history" element={<Payhistory />} />
        <Route path="/dams" element={<Dams />} />
        <Route path="/uims/users" element={<Users />} />
        <Route path="/uims/coupon" element={<Coupon />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
  );
};

export default App;
