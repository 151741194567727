import { useMutation, UseMutationOptions } from "react-query";
import { AxiosError } from "axios";

import request from "common/request";

type LogoutMutationVariable = {

};

export const usePostLogout = (
  options?: UseMutationOptions<any, AxiosError, LogoutMutationVariable>
) => {
  return useMutation<any, AxiosError, LogoutMutationVariable>(
    async () => {
      try {
        const response = await request.post(`/admin/logout`);

        return response;
      } catch (e) {
        // alert("There was an error. Please try again later.");
      }
    },
    {
      ...options,
    }
  );
};

export default usePostLogout;
