import React, { useState } from "react";
import styled from "@emotion/styled";
import { Table } from "components/ui";
import { SelectColumnFilter } from "components/ui/Table/Filter";

const getZipCodeCell = ({ value }: any) => {
  return `${value?.slice(1)}`;
};

const isSubsCell = ({ value }: any) => {
  if ( value === '구독중') {
    return <SubsText>{value}</SubsText>
  } else {
    return <UnSubsText>{value}</UnSubsText>
  }
} 


const Elem: React.FC<any> = ({ getCheckListInUIMS, data }) => {
  // const { data, isLoading } = useGetLocationList();
  const columnData = [
    { 
      Header : () => null,
      id: 'expander',
      //@ts-ignore
      Cell: ({row}) => (
        <span {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ?  '👇' : '👉'}
        </span>
      )
    },
    {
      accessor: "id",
      Header: "DBNumber",
      id: "id",
      // disableSortBy: true,
      disableFilters: true,
      disableCheckbox: true,
    },
    {
      accessor: "photyId",
      Header: "회원번호",
      id: "photyId",
      disableFilters: true,
    },
    {
      accessor: "name",
      Header: "고객명",
      id: "name",
      disableSortBy: true,
      disableFilters: true,
    },
    {
      accessor: "isSubs",
      Header: "구독여부",
      id: "isSubs",
      disableSortBy: true,
      Filter: SelectColumnFilter,
      filter: "equals",
      Cell: isSubsCell
    },
    {
      accessor: "mobile",
      Header: "전화번호",
      id: "mobile",
      disableSortBy: true,
      disableFilters: true,
    },
    {
      accessor: "zipCode",
      Header: "우편번호",
      id: "zipCode",
      disableSortBy: true,
      disableFilters: true,
      Cell: getZipCodeCell,
    },
    {
      accessor: `address[1]`,
      Header: "주소",
      id: "address",
      disableSortBy: true,
      disableFilters: true,
    },
    {
      accessor: `address[2]`,
      Header: "상세 주소",
      id: "detailAddress",
      disableSortBy: true,
      disableFilters: true,
    },
    {
      accessor: `signUpDay`,
      Header: "가입 날짜",
      id: "signUpYear",
      disableSortBy: true,
      disableFilters: true,
      // Filter: SelectColumnFilter,
      // Cell: changeDateFormatCell
    },
    {
      accessor: `subsDay`,
      Header: "구독 날짜",
      id: "subsYear",
      disableSortBy: true,
      disableFilters: true,
      // Filter: SelectColumnFilter,
      // Cell: changeDateFormatCell
    },
  ];
  
  const hiddenColumn = ['id', 'expander', 'isSubs', 'user_tier_id'];
  const [filter, setFilter] = useState("");

  const handleInputChange = (e: any) => {
    const { value } = e.currentTarget;
    setFilter(value);
  };

  const getCheckList = async (e: any) => {
    getCheckListInUIMS(e);
  };

  return (
    <>
      <FilterInput
        value={filter}
        onChange={handleInputChange}
        placeholder="name"
      />
      <Table
        columnData={columnData}
        bodyData={data || []}
        getCheckList={getCheckList}
        filters={["name"]}
        filter={filter}
        hiddenColumn={hiddenColumn}
      />
    </>
  );
};

export default Elem;

const FilterInput = styled.input`
  border: 1px solid black;
  border-radius: 5%;
  padding: 0.4rem;
  margin-bottom: 10px;
`;

const SubsText = styled.p`
  font-size: 12px;
  color: green;
`

const UnSubsText = styled.p`
  font-size: 12px;
  font-weight: bold;
  color: red;
`
