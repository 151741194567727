import React from "react";
import styled from "@emotion/styled";
import { useGetUserData } from "hooks/api";
import { Table } from "components/ui";
import { CheckOutlined } from "@ant-design/icons";
import { SelectColumnFilter } from "components/ui/Table/Filter";
import { useGetOrderDataByFilter } from 'hooks/api';

const isUpdateCell = ({ value }: any) => {
  if (value === true) {
    return <UplodadText>Done</UplodadText>
  } else {
    return <NoUploadText>no upload</NoUploadText>
  }
}

const zipCode = ({ value }: any) => {
  return `${value?.slice(1)}`;
  // return `${parseInt(value)}`;
};

const isPhoto = ({ value }: any) => {
  if (Boolean(value)) {
    return <AlreadyDownload />;
  }
  return <PreDownload />;
};

const PreOrders: React.FC<any> = ({ getCheckListInLMS, value, typeId, FilterOptions }) => {
  const { data } = useGetUserData();
  // const { data, isLoading } = useGetLocationList();
  // console.log('data',data)
  const columnData = [
    { 
      Header : () => null,
      id: 'expander',
      //@ts-ignore
      Cell: ({row}) => (
        <span {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ?  '👇' : '👉'}
        </span>
      )
    },
    {
      accessor: "id",
      Header: "DBNumber",
      id: "id",
      // disableSortBy: true,
      disableFilters: true,
      disableCheckbox: true,
    },
    {
      accessor: "user[3]",
      Header: "회원번호",
      id: "photyId",
      disableFilters: true,
    },
    {
      accessor: "payhistory",
      Header: "주문번호",
      id: "orderNumber",
      // disableSortBy: true,
      disableFilters: true,
    },
    {
      accessor: "userTier",
      Header: "회원등급",
      id: "userTier",
      disableSortBy: true,
      Filter: SelectColumnFilter,
      filter: "equals",
    },
    {
      accessor: "year",
      Header: "연도",
      id: "year",
      disableSortBy: true,
      Filter: SelectColumnFilter,
      // disableFilters: true,
    },
    {
      accessor: "month",
      Header: "월",
      id: "month",
      disableSortBy: true,
      Filter: SelectColumnFilter,
      filter: "equals",
      // disableFilters: true,
    },
    {
      accessor: "orderDate",
      Header: "주문일",
      id: "orderDate",
      disableSortBy: true,
      disableFilters: true,
    },
    {
      accessor: "user[0]",
      Header: "고객명",
      id: "name",
      disableSortBy: true,
      disableFilters: true,
    },
    {
      accessor: "zipCode",
      Header: "우편번호",
      id: "zipCode",
      disableSortBy: true,
      disableFilters: true,
      Cell: zipCode,
    },
    {
      // accessor: "user[1]",
      accessor: "phone",
      Header: "전화번호",
      id: "phone",
      disableSortBy: true,
      disableFilters: true,
      // Cell: phoneNumber,
    },
    {
      accessor: `address[1]`,
      Header: "주소",
      id: "defaultAddress",
      disableSortBy: true,
      disableFilters: true,
    },
    {
      accessor: `address[2]`,
      Header: "상세 주소",
      id: "detailAddress",
      disableSortBy: true,
      disableFilters: true,
    },
    {
      accessor: "subscribes",
      Header: "상품종류",
      id: "subscribes",
      disableSortBy: true,
      disableFilters: true,
    },
    {
      accessor: "order_type_id",
      Header: "옵션명",
      id: "order_type_id",
      disableSortBy: true,
      Filter: SelectColumnFilter,
      filter: "equals",
    },
    {
      accessor: "isUpdate",
      Header: "업로드 유무",
      id: "is_update",
      // disableSortBy: true,
      disableFilters : true,
      Cell : isUpdateCell,
    },
    {
      accessor: "totalprice",
      Header: "금액",
      id: "totalprice",
      disableSortBy: true,
      disableFilters: true,
    },
    {
      accessor: "invoiceNumber",
      Header: "송장번호",
      id: "invoiceNumber",
      disableSortBy: true,
      disableFilters: true,
    },
    {
      accessor: "delivery_stage_id",
      Header: "배송상태",
      id: "deliveryStage",
      disableSortBy: true,
      disableFilters: true,
    },
    {
      accessor: "is_photo",
      Header: "사진 다운로드 여부",
      id: "isPhoto",
      disableSortBy: true,
      Cell: isPhoto,
      Filter: SelectColumnFilter,
      filter: "equals",
      // disableFilters: true,
    },
  ];

  const hiddenColumn = ['id', "totalprice"];
  const getCheckList = async (e: any) => {
    getCheckListInLMS(e);
  };
  

  return (
    <Container>
      <Table
        columnData={columnData}
        bodyData={data || []}
        getCheckList={getCheckList}
        filters={["name"]}
        filter={value}
        hiddenColumn={hiddenColumn}
      />
    </Container>
  );
};

export default PreOrders;

const Container = styled.div`
  margin-top: 10px;
  width: 100%;
`

const AlreadyDownload = styled(CheckOutlined)`
  color: green;
  font-size: 20px;
  font-weight: 500;
`;
const PreDownload = styled(CheckOutlined)`
  color: lightgray;
  font-size: 20px;
  font-weight: 500;
`;

const FilterInput = styled.input`
  border: 1px solid black;
  border-radius: 5%;
  padding: 0.4rem;
  margin-bottom: 10px;
  width: 150px;
  margin-right: auto;
`;


const UplodadText = styled.p`
  font-size: 12px;
  color: green;
`

const NoUploadText = styled.p`
  font-size: 12px;
  font-weight: bold;
  color: red;
`