import { useMutation, UseMutationOptions } from "react-query";
import axios, { AxiosError } from "axios";
import request from "common/request";

type getIdsVariable = {
  ids:number[]
};

export const usePostBackedUser = (
  options?: UseMutationOptions<any, AxiosError, getIdsVariable>
) => {
  return useMutation<any, AxiosError, getIdsVariable>(
    async ({ ids }) => {
      try {
        const response = await request.post(`/backed/users`,{
          ids
        });
        return response;
      } catch (e) {
        alert("There was an error. Please try again later.");
      }
    },
    {
      ...options,
    }
  );
};

export default usePostBackedUser;