import styled from '@emotion/styled';
import { DownloadButton } from "components/ui";
import { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import * as XLSX from 'xlsx';
import FolderNameInput from "../FolderNameInput";
import DeliveryUpload  from "../DeliveryFileUpload";

const ButtonContainer:React.FC<any> = ({ checkList, tab }) => {
  const [ showModal, setShowModal ] = useState(false);
  const csvLinkref = useRef<any>(null);
  const today = new Date();

  const CSV_HEADERS = [
    { label: "수령자명", key: "name" },
    { label: "우편번호", key: "zipCode" },
    { label: "수령자 연락처", key: "phone" },
    { label: "수령자 주소", key: "address" },
    { label: "수령자 상세주소", key: "detailAddress" },
    { label: "결제", key: "isPay" },
    { label: "구독권", key: "isSubsCoupon" },
    { label: "북", key: "isBook" },
    { label: "업로드 한 사진 수", key: "uploadPhotos" },
    { label: "이번달 사진 수", key: "photos" },
    { label: "페이지", key: "pages" },
    { label: "업로드 여부", key: "isUpdate" },
    { label: "소급 업로드", key: "uploadPast" },
  ];
  
  const allDownloadInvoice = (e: any) => {
    const ws = XLSX.utils.json_to_sheet(checkList);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'raw');

    XLSX.writeFile(wb, 'data.xlsx')
  };

  const getFileName = () => {
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const day = today.getDate();
    return `${year}-${month}-${day}`;
  };

  const openModal = () => {
    setShowModal(true)
  }

  const closeModal = () => {
    if (showModal === true) return setShowModal(false)
  }

  return (
    <>
      <ButtonContaier>
        <CSVLink
          data={ checkList || []}
          headers={CSV_HEADERS}
          filename={getFileName()}
          ref={csvLinkref}
        />
        <DownloadButton
          icon="download"
          layoutVariant="outlined"
          variant="default"
          size="tiny"
          onClick={allDownloadInvoice}
        >
          명단 다운로드
        </DownloadButton>
        <FolderButonContainer>
          <DownloadButton
            icon="file"
            layoutVariant="contained"
            variant="default"
            size="tiny"
            onClick={openModal}
          >
            사진파일 폴더링
          </DownloadButton>
          {showModal && <FolderNameInput showModal={showModal} closeModal={closeModal} checkData={checkList} />}
        </FolderButonContainer> 
        <DeliveryUpload tab={tab} />
      </ButtonContaier>
    </>
  )
};

export default ButtonContainer;


const ButtonContaier = styled.div`
  /* width: 450px; */
  height: 100%;
  text-align: center;
  margin-left: auto;
  justify-content: space-evenly;
  display: flex;
  flex-direction: row;
`;

const FolderButonContainer= styled.div`
  margin-left: 8px;
  margin-right: 9px;
`;