import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import UsinCouponTable from "components/pages/UIMS/coupons/GeneralCoupon/UsingCouponTable";


const Elem: React.FC<any> = ({ usingCouponList }) => {
  const [ filter, setFilter ] = useState("");

  const handleInputChange = async (e:any) => {
    const { value } = e.currentTarget;
    setFilter(value)
  }
  console.log('usingCouponList', usingCouponList)
  return (
    <Container>
      <Title>사용중인 쿠폰</Title>
      <FilterInput
          value={filter}
          onChange={handleInputChange}
          placeholder="쿠폰번호"
        />
      <UsinCouponTable
        value={filter}
        data={usingCouponList}
      />
    </Container>
  );
};

export default Elem;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  font-weight: normal;
  margin-top: 10px;
`;

const FilterInput = styled.input`
  border: 1px solid black;
  border-radius: 5px;
  padding: 0.4rem;
  margin-bottom: 3px;
  margin-right: auto;
  margin-top: 10px;
`;
