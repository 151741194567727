import React, { useMemo } from "react";
import styled from "@emotion/styled";
import { Table } from "components/ui";


const AllOrders: React.FC<any> = ({ getCheckListInLMS, value, data }) => {  
  const mockData = [{
    "id": 1,
    "orderNumber": "0231227A0414554177",
    "externalPayNumber": "T58b7e6429510b3a6c37",
    "payState": "결제완료",
    "photyId": "00001129",
    "name": "이페타",
    "payType": "카카오",
    "payYear": 2023,
    "payMonth": 11,
    "paymentAt": "2022-04-15  10:00:15"
  }]
  const columnData = [
    {
      accessor: "id",
      Header: "DBNumber",
      id: "id",
      // disableSortBy: true,
      disableFilters: true,
      disableCheckbox: true,
    },
    {
      accessor: "orderNumber",
      Header: "주문번호",
      id: "orderNumber",
      disableSortBy: true,
      disableFilters: true,
    },
    {
      accessor: "externalPayNumber",
      Header: "외부 결제 번호",
      id: "externalPayNumber",
      disableSortBy: true,
      disableFilters: true,
    },
    {
      accessor: "payState",
      Header: "결제 상태",
      id: "payState",
      disableSortBy: true,
      disableFilters: true,
    },
    {
      accessor: "photyId",
      Header: "회원번호",
      id: "photyId",
      disableFilters: true,
    },
    {
      accessor: "name",
      Header: "고객명",
      id: "name",
      disableSortBy: true,
      disableFilters: true,
    },
    {
      accessor: "payType",
      Header: "결제 수단",
      id: "payType",
      disableSortBy: true,
      disableFilters: true,
    },
    {
      accessor: "payYear",
      Header: "연도",
      id: "payYear",
      disableSortBy: true,
      disableFilters: true,
    },
    {
      accessor: "payMonth",
      Header: "월",
      id: "payMonth",
      disableSortBy: true,
      disableFilters: true,
    },
    {
      accessor: "paymentAt",
      Header: "결제일시",
      id: "paymentAt",
      disableSortBy: true,
      disableFilters: true,
    },
  ];

  const hiddenColumn = [ 'id' ]

  const getCheckList = async (e: any) => {
    getCheckListInLMS(e);
  };
  
  return (
    <Container>
      <Table
        columnData={columnData}
        bodyData={mockData || []}
        getCheckList={getCheckList}
        filters={["name", "phone"]}
        filter={value}
        hiddenColumn={hiddenColumn}
      />
    </Container>
  );
};

export default AllOrders;

const Container = styled.div`
  margin-top: 10px;
  width: 100%;
`;