const color = `
  :root {
    --experimental-blue: #048EF9;
    --blue: #1f29ca;
    --red: #C6005A;
    --orange: #F97316;
    --red-light: #FBD7E0;
    --yellow: #F6CF43;
    --yellow-light: #f8ebbd;
    --warning-bg: #fcf7f3;
    --grey-navbar: #f5f5f5;
    --default-bg: #F9FAFB;
    --grey: #909AAE;
    --grey-light: #C4C4C4;
    --unselected: #909AAE;
    --dark-blue:#0068A5;
    --light-blue:#c9dff2;
    --white: #fff;
    --navy: #003156;
    --lighter-navy: #15588a;
    --pearl-blue:#3f51b5;
    --pearl-blue: #f9fafb;
    --dark-grey: #4d4d4d;
    --light-grey: #e5e5e5;
    --warning-red: #C6005A;
    --warning-yellow: #F7CF43;
    --information-green: #68D057;
    --inactive-grey:#909aae;
    --highlighted-background:#EEFAFF;
    --ascent-grey: #E5E7EB;
    --white-background:#ffffff;
    --cancle-red-bg:#FBD7E0;
    --dark-overlay: rgba(18, 8, 45, 0.35);
    --information-green-bg:#CDF7D4;
    --pale-green:#FDFFFC;
    --black:#000000;
    --extended-orange:#F97316;
    --deep-navy:#111827;
    --deep-grey:#242424;
    --cyan-light: #baf2f2;
    --cyan-lightest: #EEFCFC;
    --black: #000000
  }
`;

export default color;
