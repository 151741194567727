import React, { ButtonHTMLAttributes } from "react";
import {
  AiOutlineFolderOpen,
  AiOutlineLoading,
  AiOutlinePlus,
  AiOutlineRise,
  AiOutlineDownload
} from "react-icons/ai";
import { FaRegSave } from "react-icons/fa";
import { FiPlusSquare, FiUserPlus } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

import { css } from "@emotion/react";
import styled from "@emotion/styled";

interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  layoutVariant?: "contained" | "outlined";
  variant?: "primary" | "secondary" | "tertiary" | "danger" | "default";
  to?: String;
  isLoading?: boolean;
  icon?:
    | "file"
    | "plus"
    | "plus-no-border"
    | "google"
    | "invite"
    | "save"
    | "escalate" // FIXME: 별도의 컴포넌트로 분리할 것
    | "download";
  iconRight?: boolean;
  expand?: boolean;
  onClick?: any;
  htmlType?: "submit";
  size?: "tiny" | "small" | "medium" | "large";
}

const handleColorType = (variant: string) => {
  switch (variant) {
    case "primary":
      return "var(--experimental-blue)";
    case "secondary":
      return "var(--information-green)";
    case "tertiary":
      return "var(--dark-blue)";
    case "danger":
      return "var(--warning-red)";
    case "default":
      return "var(--white-background)";
    default:
      return "none";
  }
};

const Button: React.FC<IButton> = ({
  children,
  onClick,
  htmlType,
  ...props
}) => {
  const {
    layoutVariant,
    variant,
    disabled,
    isLoading,
    iconRight,
    expand,
    size,
    icon,
    to = "",
  } = props;

  const navigate = useNavigate();

  const onClickHandler = (
    to: any,
    onClick: Function,
    e: React.MouseEvent<HTMLElement>
  ) => {
    if (to) {
      navigate(to);
    }

    if (typeof onClick === "function") {
      onClick(e);
    }
  };

  return (
    <ButtonComponent
      layoutVariant={layoutVariant}
      variant={variant}
      disabled={disabled || isLoading}
      expand={expand}
      onClick={(e: React.MouseEvent<HTMLElement>) =>
        onClickHandler(to, onClick, e)
      }
      htmlType={htmlType}
      size={size ? size : "small"}
      {...props}
    >
      {icon === "plus" && !isLoading && <FiPlusSquare className="icon" />}
      {icon === "plus-no-border" && !isLoading && (
        <AiOutlinePlus className="icon" />
      )}
      {icon === "file" && !isLoading && (
        <AiOutlineFolderOpen className="icon" />
      )}

      {icon === "invite" && !isLoading && <FiUserPlus className="icon" />}
      {icon === "save" && !isLoading && <FaRegSave className="icon" />}
      {icon === "escalate" && !isLoading && <AiOutlineRise className="icon" />}
      {icon === "download" && !isLoading && <AiOutlineDownload className="icon" />}

      {isLoading && <AiOutlineLoading className="isLoading" />}
      {!isLoading && <span>{children}</span>}
    </ButtonComponent>
  );
};

const ButtonComponent = styled.button<IButton>((props) => {
  const {
    size,
    variant,
    disabled,
    layoutVariant,
    iconRight,
    expand,
    isLoading,
  } = props;

  return css`
    display: flex;
    justify-content: center;
    height: fit-content;
    align-items: center;
    min-width: max-content;
    border: 0.1px solid var(--grey);
    color: var(--grey);
    line-height: 20px;
    outline: none;
    margin-left: 3px;
    margin-right: 3px;
    width: 130.8px;

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }

    ${size === "tiny" &&
    `
    padding: 8px 15px 9px 15px; 
      border-radius: 5px;
      span {
        font-size: 10px;
        line-height: 13px;
      }
      svg {
        width: 18px;
      }
    `}
    ${size === "small" &&
    `
      padding: 12px 24px;
      border-radius: 8px;
      span {
        font-size: 14px;
        line-height: 16px;
      }
    `}

    ${size === "medium" &&
    `
      padding: 16px 28px;
      border-radius: 8px;
      span {
        font-size: 16px;
        line-height: 18px;
      }
    `}

    ${size === "large" &&
    `
      padding: 16px 28px;
      border-radius: 50px;
      max-width: 100%;
      width: 34%;
      span {
        font-size: 16px;
        line-height: 18px;
      }
    `}

    background-color: ${variant
      ? handleColorType(variant)
      : "var(--deep-navy)"};

    &:hover {
      background: var(--light-grey);
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.250929);
      color: ${variant ? handleColorType(variant) : "var(--deep-navy)"};
    }

    ${disabled &&
    `
      background-color: var(--ascent-gray);
      color: var(--grey);
      cursor: default;
      &:hover {
        border-color: ${variant ? handleColorType(variant) : "var(--deep-navy)"}
        background-color: ${
          variant ? handleColorType(variant) : "var(--deep-navy)"
        };
      color: var(--deep-navy);
      }
    `}
    .isLoading {
      animation: load 1.1s infinite linear;
    }

    @keyframes load {
      0% {
        -webkit-transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
    .icon {
      margin-right: 10px;
    }
    ${iconRight &&
    `
      flex-direction: row-reverse;
      .icon {
        margin-right: -7px;
        margin-left: 10px;
      }
    `}

    ${expand &&
    `
      width: 100%;
    `}

    ${!expand &&
    `
      max-width: fit-content;
    `}

    &:active,
    &:focus {
      outline: 0;
    }
  `;
});

export default Button;
