import { useEffect, useState } from 'react';
import SelectFilter  from "components/ui/Select";

import styled from "@emotion/styled";

interface Option {
  className?:string;
  value: number;
  name: string;
}

const payState:Option[] = [
  { value: 1, name: "전체" },
  { value: 2, name: "카드 등록 성공" },
  { value: 3, name: "결제성공" },
  { value: 4, name: "결제 실패" },
  { value: 5, name: "구독권 결제" },
]; //원래는 select 태그 안에 들어가는 애들을 배열로 만들어준다.

interface FileterInfo {
  payState    : number;
}

const Elem:React.FC<any> = ({ getFilterInfo }) => {
  const [ fileterOptions, setFilterOptions ] = useState<FileterInfo>({
    payState  : 1,
  })

  const handlePayState = async ( selectedOption:Option ) => {
    setFilterOptions((preSelectedOptions) => ({
      ...preSelectedOptions,
      payState: selectedOption?.value
    }))
  };

  useEffect(() => {
    if (fileterOptions) {
      getFilterInfo(fileterOptions)
    }
  },[fileterOptions])

  return (
    <FilterContainer>
      <FilterWrapper>
        <DropDownContainer>
          <FilterName>업로드 여부</FilterName>
          <DropDownWrapper>
            <SelectFilter
              options={payState}
              onChange={handlePayState}
            />
          </DropDownWrapper>
        </DropDownContainer>
      </FilterWrapper>
    </FilterContainer>
  )
};

export default Elem;

const FilterContainer = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  height: 70px;
  display: flex;
  flex-direction: row;
`;

const FilterWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const DropDownContainer = styled.div`
  width: 130px;
  margin-right: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.last {
    margin-right: 0;
  }
`;
const FilterName = styled.div`
  height: 20px;
  font-size: 15px;
  color: black;
  font-style: normal;
  font-weight: 500;
`;
const DropDownWrapper = styled.div`
  width: 100%;
  height: 30px;
`;


