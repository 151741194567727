import { AxiosError } from 'axios';
import request from 'common/request';
import { useQuery } from 'react-query';

const useGetAllSubscribeUserList = (options?: any) => {
  return useQuery<any, AxiosError>(
    ['userallUser'],
    async () => {
      const { data } = await request.get<any>(`/all/users`);
      
      return data?.userData
    },
    {
      cacheTime: 30 * 1000,
      ...options,
    }
  );
};

export default useGetAllSubscribeUserList;
