import React, { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";
import styled from "@emotion/styled";

import { size } from "../../style/device";
import { NavLink } from "react-router-dom";

const SideTapbar: React.FC<{ disabled?: boolean; children?: React.ReactNode }> = () => {
  const location = useLocation();

  
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [location]);

  const [activeTabs, setActiveTabs] = useState<string[]>([]);

  const handleTabClick = (tabId: string) => {
    if (activeTabs.includes(tabId)) {
      setActiveTabs((prevTabs) => prevTabs.filter((id) => id !== tabId));
    } else {
      setActiveTabs((prevTabs) => [...prevTabs, tabId]);
    }
  };

  const handleSubTabClick = (tabId: string) => {
    setActiveTabs((prevTabs) => {
      if (!prevTabs.includes(tabId)) {
        return [...prevTabs, tabId];
      }
      return prevTabs;
    });
  };

  const renderSubTabs = (tabId: string) => {
    if (activeTabs.includes(tabId)) {
      return (
        <SubTabContainer active={true}>
          {tabId === 'LMS' && (
            <>
              <SubTabButton
                to="/lms/orders"
                className="active"
                onClick={() => handleSubTabClick('LMS')}
              >
                주문
              </SubTabButton>
              <SubTabButton
                to="/lms/download"
                className="active"
                onClick={() => handleSubTabClick('LMS')}
              >
                다운로드
              </SubTabButton>
              <SubTabButton
                to="/lms/reguler"
                className="active"
                onClick={() => handleSubTabClick('LMS')}
              >
                정기배송
              </SubTabButton>
            </>
          )}
          {tabId === 'PMS' && (
            <SubTabButton to="/pms/history" className="active">
              결제이력
            </SubTabButton>
          )}
          {tabId === 'Dams' && (
            <SubTabButton to="/dams" className="active">
              Data
            </SubTabButton>
          )}
          {tabId === 'Uims' && (
            <>
              <SubTabButton to="/uims/users" className="active">
                Users
              </SubTabButton>
              <SubTabButton to="/uims/coupon" className="active">
                Coupon
              </SubTabButton>
            </>
          )}
        </SubTabContainer>
      );
    }
    return null;
  };

  return (
    <Container>
      <TapBar>
        <TapBox
          active={activeTabs.includes('LMS')}
          onClick={() => handleTabClick('LMS')}
        >
          LMS
        </TapBox>
        {renderSubTabs('LMS')}
        <TapBox
          active={activeTabs.includes('PMS')}
          onClick={() =>handleTabClick('PMS')}
        >
          PMS
        </TapBox>
        {renderSubTabs('PMS')}
        <TapBox
          active={activeTabs.includes('Dams')}
          onClick={() =>handleTabClick('Dams')}
        >
          DAMS
        </TapBox>
        {renderSubTabs('Dams')}
        <TapBox
          active={activeTabs.includes('Uims')}
          onClick={() =>handleTabClick('Uims')}
        >
          UIMS
        </TapBox>
        {renderSubTabs('Uims')}
      </TapBar>
    </Container>
  );
};

export default SideTapbar;

const NavButtonWrapper = styled.div`
  display: flex;
  font-size: 14px;
  color: var(--black);
  padding: 10px 20px;

  .wide-icon {
    width: 16px;
    margin-top: -1px;
  }

  * {
    margin-right: 5px;
  }
`;

const Container = styled.section`
  /* min-width: ${size.mobileM}; */
  min-height: 100vh;
  flex-direction: column;
  margin-right: 10px;
`;

const TapBar = styled.div`
  width: 100px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const TapBox = styled.div<TabProps>`
  /* margin-left: 75px; */
  display: flex;
  font-size: 14px;
  color: var(--black);
  padding: 10px 20px;
  font-size: large;
  font-weight: bold;
`

interface TabProps {
  active: boolean;
}

const SubTabContainer = styled.div<TabProps>`
  /* display: ${({ active }) => (active ? 'block' : 'none')}; */
  display: flex;
  flex-direction: column;
`;
const SubTabButton = styled(NavLink)`
  border: none;
  padding: 10px;
  margin-left: 20px;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  color: black;
`;
