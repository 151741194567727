import { AxiosError } from "axios";
import request from "common/request";
import { useQuery } from "react-query";

interface FilterOptionsProps {
  userTier?      : number,
  isDownload?    : number,
  deliveryStage? : number,
  orderType?     : number,
  year?    :  null | number;
  month?    :  null | number;
}

const useGetOrderDataByFilter = (filterOptions:FilterOptionsProps ) => {
  return useQuery<any, AxiosError>(
    ["filterData"],
    async () => {
      console.log('filterOptions', filterOptions)
      const { data } = await request.get<any>(`/orders`,{
        params: {
          year      : filterOptions?.year || 0,
          month     : filterOptions?.month || 0,
          typeId    : filterOptions?.orderType,
          tier      : filterOptions?.userTier,
          count     : 1,
          delivery  : filterOptions?.deliveryStage,
          down      : filterOptions?.isDownload,
        }
      });
      return data?.data;
    },
    {
      cacheTime: 30 * 1000,
    }
  );
};

export default useGetOrderDataByFilter;
