import React, { useState } from "react";
import styled from "@emotion/styled";
import { useGetDownloadPhotoByUserId } from "hooks/api";
import { CloseOutlined } from '@ant-design/icons';
import LoadingOverlay from 'components/ui/PIMS/LoadingOverlay';

const Elem: React.FC<any> = ({ showModal, closeModal, checkData}) => {
  const [folderName, setFolderName] = useState("");
  const [ loading, setLoading ] = useState(false);
  const { mutateAsync: postDownlod } = useGetDownloadPhotoByUserId();
  const ids = checkData;
  console.log('ids',ids)

  const onChangeFolderName = (e:any) => {
    setFolderName(e.target.value)
  };

  const goToFolder = async (ids: any) => {
    setLoading(true)
    const response =  await postDownlod({ ids, folderName });
    if( response?.statusCode === 200) {
      setLoading(false);
      closeModal();
    } else if (response?.statusCode === 400) {
      alert("선택된 사진 파일이 없습니다.")
      setLoading(false);
      closeModal();
    } else {
      alert(response.data.message);
      setLoading(false);
      closeModal();
    }
  }

  return (
    <>
      {showModal? (
        <Background>
        <ModalContainer onClick={e => e.stopPropagation()}>
          <CloseButtonContainer>
            <CloseButton onClick={closeModal}></CloseButton>
          </CloseButtonContainer>
          <InputContainer>
            <InputBox
              id='folderName'
              name='folderName'
              placeholder="폴더명을 입력하세요"
              onChange={onChangeFolderName}
            />
          </InputContainer>
          <FolderingButtonContainer>
            <FolderingButton onClick={() => goToFolder(ids)}>폴더 만들기</FolderingButton>
          </FolderingButtonContainer>
          < LoadingOverlay loading={loading}/>
        </ModalContainer>
      </Background>
      ): null}
    </>
  );
};

export default Elem;

const Background = styled.div`
  position:fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0,0,0,0.50);
  z-index: 0;
`

const ModalContainer = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-height: 80%;
  width: 500px;
  height: 40%;
  padding: 16px;
  background-color: white;
  border-radius: 10px;
  text-align: center;
  display:flex;
  flex-direction: column;
`

const CloseButton = styled(CloseOutlined)`
  position: absolute;
  /* left: 80px; */
  
  top: 19px;
`
const CloseButtonContainer =styled.div`
  height: 10%;
  width: 10%;
  margin-left: auto;
`
const InputContainer = styled.div`
  height: 70px;
  width: 300px;
  margin: 60px auto 0px;
`

const InputBox =styled.input`
  height: 100%;
  width: 100%;
  border:2px solid black;
`

const FolderingButtonContainer = styled.div`
  height: 40px;
  width: 200px;
  margin: auto;
`

const FolderingButton = styled.button`
  height: 100%;
  width: 100%;
`