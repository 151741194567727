import { useMutation, UseMutationOptions } from "react-query";
import { AxiosError } from "axios";

import request from "common/request";

type LoginMutationVariable = {
  userId: string;
  password: string;
};

export const usePostLogin = (
  options?: UseMutationOptions<any, AxiosError, LoginMutationVariable>
) => {
  return useMutation<any, AxiosError, LoginMutationVariable>(
    async ({ userId, password }) => {
      try {
        //@ts-ignore
        const response = await request.post(`/admin/login`, {
          userId,
          password,
        });
        return response;
      } catch (e) {
        //@ts-ignore
        const response = e.response;
        if ( response.status === 409){
          if (response.data.message === " Your ID has not been entered.") {
            window.alert("Your ID has not been entered.!!!!")
          } else if (response.data.message === "No password has been entered.") {
            window.alert("No password has been entered.!!!")
          } else {
            window.alert("Password do not match!!!")
          }
        } else if (response.status === 400) {
          window.alert("You're email not found!!")
        }
        // alert("There was an error. Please try again later.");
      }
    },
    {
      ...options,
    }
  );
};

export default usePostLogin;
