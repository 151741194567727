import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import YearMonthPicker from "components/ui/PIMS/YearMonthPicker";
import { usePostRegulerList } from "hooks/api";
import Button from 'components/ui/DownloadButton';
import FilterContainer from "./FilterContainer";
import HistoryTable from "./historyTable";

interface SelectedDateInfo {
  year: number;
  month: number;
};

interface FilterOptionsProps {
  payState    : number,
};

const Elem: React.FC<any> = () => {
  const [ filter, setFilter ] = useState("");
  const [ checkList, setCheckList ] = useState([{}]);
  const [ selectedDate, setSelectedDate ] = useState<SelectedDateInfo>({
    year: 0,
    month: 0
  });
  const [ filterOptions, setFilterOptions ] = useState<FilterOptionsProps>({
    payState  : 1,
  })

  const [ data, setData ] = useState();

  const handleInputChange = async (e: any) => {
    const { value } = e.currentTarget;
    setFilter(value);
  };

  const getCheckList = async (e: any) => {
    setCheckList(e);
  };

  const handleRegulerListButton = async () => {
    if (selectedDate.year === 0 && selectedDate.month === 0) {
      alert("조회할 달을 선택해 주세요")
    } else {
      console.log(filterOptions)
      // const result = await postRegulerList({
      //   year: selectedDate.year,
      //   month: selectedDate.month,
      //   isUpload : filterOptions.isUpload,
      //   isBook : filterOptions.isBook,
      //   isAdditional: filterOptions.isAdditional,
      //   isPay       : filterOptions.isPay,
      // })
    
      // const regulerUserList = result.data.RegulerList;
      // setData(regulerUserList)
    }
  }

  const getYearMonth = async (e:any) => {
    if (e) {
      const selectedDate = new Date(e);
      const selectedYear = selectedDate.getFullYear();
      const selectedMonth = selectedDate.getMonth() + 1
      setSelectedDate((preSeletecdOptions) => ({
        ...preSeletecdOptions,
        year: selectedYear,
        month: selectedMonth
      }))
    } 
  }

  const getFilterInfo = async (e:any) => {
    setFilterOptions((preFilterOptions) =>({
      ...preFilterOptions,
      payState : e?.payState,
    }))
  };
  
  return (
    <>
      <Topwrapper>
        <FilterInput
          value={filter}
          onChange={handleInputChange}
          placeholder="이름, 전화번호"
        />
      </Topwrapper>
      <FilterWrapper>
        <FilterContainer getFilterInfo={getFilterInfo}/>
        <DatePickerContainer>
          <YearMonthPicker getYearMonth={getYearMonth} />
        </DatePickerContainer>
        <SelectedButton
          layoutVariant="outlined"
          variant="default"
          size="tiny"
          onClick={handleRegulerListButton}
        >조회</SelectedButton>
      </FilterWrapper>
      <HistoryTable
        value={filter}
        getCheckListInLMS={getCheckList}
        // data={data}
      />
    </>
  );
};

export default Elem;
const FilterInput = styled.input`
  border: 1px solid black;
  border-radius: 5px;
  padding: 0.4rem;
  margin-bottom: 10px;
  /* margin-right: auto; */
`;

const Topwrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  margin-left: auto;
`;

const DatePickerContainer = styled.div`
  width: 200px;
  margin-top: 20px;
`

const CountOfSubscriber = styled.div`
  display: flex;
  flex-direction: row;
  
  p {
    display: flex;
    font-size: 18px;
    margin-right: 10px;
  }
`;
const FilterWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align:  center;
`;
const SelectedButton = styled(Button)`
  margin-top: 16px;
`;