import React, { useMemo } from "react";
import styled from "@emotion/styled";
import { Table } from "components/ui";
import { SelectColumnFilter } from "components/ui/Table/Filter";

const isUpdateCell = ({ value }: any) => {
  if (value === 'upload') {
    return <UplodadText>업로드 완료</UplodadText>
  } else if (value === 'noUpload') {
    return <RedText>미업로드</RedText>
  } else {
    return <ErrorText>Error</ErrorText>
  }
};

const isBook = ({ value}: any) => {
  if (value === true) {
    return <UplodadText>O</UplodadText>
  } else {
    return <RedText>X</RedText>
  }
}

const zipCode = ({ value }: any) => {
  return `${value?.slice(1)}`;
};

const isPay = ({ value}: any) => {
  if (value === false ) {
    return <RedText>미결제</RedText>;
  } 
  return <ChangeBlackText>결제완료</ChangeBlackText>;
};

const isSubsCoupon = ({ value}: any) => {
  if (value === true ) {
    return <ChangeBlackText>사용중</ChangeBlackText>;
  } 
  return <ChangeBlackText>미사용</ChangeBlackText>;
};



const AllOrders: React.FC<any> = ({ getCheckListInLMS, value, data }) => {  
  const columnData = [
    {
      accessor: "id",
      Header: "DBNumber",
      id: "id",
      // disableSortBy: true,
      disableFilters: true,
      disableCheckbox: true,
    },
    {
      accessor: "photyId",
      Header: "회원번호",
      id: "photyId",
      disableFilters: true,
    },
    {
      accessor: "name",
      Header: "고객명",
      id: "name",
      disableSortBy: true,
      disableFilters: true,
    },
    {
      accessor: "phone",
      Header: "전화번호",
      id: "phone",
      disableSortBy: true,
      disableFilters: true,
    },
    {
      accessor: "subscribeMonth",
      Header: "구독개월수",
      id: "subscribeMonth",
      disableSortBy: true,
      // Filter: SelectColumnFilter,
      // filter: "equals",
      disableFilters: true,
    },
    {
      accessor: "invoice",
      Header: "송장 번호",
      id: "invoice",
      disableSortBy: true,
      disableFilters: true,
    },
    {
      accessor: "isPay",
      Header: "이번달 결제 여부",
      id: "isPay",
      disableSortBy: true,
      disableFilters: true,
      Cell: isPay
    },
    {
      accessor: "isSubsCoupon",
      Header: "구독권",
      id: "subsCoupon",
      disableSortBy: true,
      disableFilters: true,
      Cell: isSubsCoupon
    },
    {
      accessor: "isBook",
      Header: "북",
      id: "isBook",
      disableSortBy: true,
      // Filter: SelectColumnFilter,
      // filter: "equals",
      disableFilters: true,
      Cell : isBook,
    },
    {
      accessor: "zipCode",
      Header: "우편번호",
      id: "zipCode",
      disableSortBy: true,
      disableFilters: true,
      Cell: zipCode,
    },
    {
      accessor: `address`,
      Header: "주소",
      id: "address",
      disableSortBy: true,
      disableFilters: true,
    },
    {
      accessor: "uploadPhotos",
      Header: "업로드 한 사진 수",
      id: "uploadPhotos",
      disableSortBy: true,
      disableFilters: true,
    },
    {
      accessor: "photos",
      Header: "사진 수",
      id: "photos",
      disableSortBy: true,
      disableFilters: true,
    },
    {
      accessor: "pages",
      Header: "페이지 수",
      id: "pages",
      disableSortBy: true,
      disableFilters: true,
    },
    {
      accessor: "isUpload",
      Header: "업로드 유무",
      id: "is_update",
      disableSortBy: true,
      disableFilters : true,
      Cell : isUpdateCell,
    },
    {
      accessor: "uploadPast",
      Header: "소급업로드",
      id: "uploadPast",
      disableSortBy: true,
      disableFilters: true,
    },
    {
      accessor: "downloadTime",
      Header: "다운로드",
      id: "isPhoto",
      disableSortBy: true,
      disableFilters: true,
    },
  ];

  const hiddenColumn = [
    'id', 
    "address", 
    "zipCode", 
    "detailAddress"];

  const getCheckList = async (e: any) => {
    getCheckListInLMS(e);
  };
  
  return (
    <Container>
      <Table
        columnData={columnData}
        bodyData={data || []}
        getCheckList={getCheckList}
        filters={["name", "phone"]}
        filter={value}
        hiddenColumn={hiddenColumn}
      />
    </Container>
  );
};

export default AllOrders;

const Container = styled.div`
  margin-top: 10px;
  width: 100%;
`;
const UplodadText = styled.p`
  font-size: 12px;
  color: green;
`;
const RedText = styled.p`
  font-size: 12px;
  font-weight: bold;
  color: red;
`;
const ErrorText = styled.p`
  font-size: 12px;
  font-weight: bold;
  color: yellow;
`;
const ChangeBlackText = styled.p`
  font-size: 12px;
  font-weight: bold;
  color: Black;
`;