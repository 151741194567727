import React from "react";
import ReactDOM from "react-dom";
import { QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import { Preflight, ThemeProvider } from "@xstyled/emotion";
import GlobalStyle from "style/global";
import theme from "style/theme";
import { queryClient } from "common/request";

import App from "./App";

function Root() {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <Preflight />
          <App />
        </ThemeProvider>
      </QueryClientProvider>
    </BrowserRouter>
  );
}

ReactDOM.render(<Root />, document.getElementById("root"));
