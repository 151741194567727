import styled from '@emotion/styled';
import UsingCouponList from "./UseCouponList";
import GeneralCouponState from "./GeneralCouponState";
import { useGetUsingCouponList } from 'hooks/api';
import { useEffect, useState } from 'react';

const BottomContainer:React.FC<any> = ({ children }) => {
  const { data } = useGetUsingCouponList();
  const [ usingCoupon, setUsingCoupon ] = useState<[]>([]);
  const [ generalCouponList, setGeneralCouponList ] = useState<[]>([])

  console.log('????',data?.couponList);
  console.log('????',data?.allGeneralCouponIds);

  useEffect(() => {
    setUsingCoupon(data?.couponList);
    setGeneralCouponList(data?.allGeneralCouponIds)
  },[data]);

  return (
    <Container>
      <LeftContainer>
        <UsingCouponList usingCouponList={usingCoupon}/>
      </LeftContainer>
      <RightContainer>
        <GeneralCouponState generalCouponList={generalCouponList} />
      </RightContainer>
    </Container>
  )
}

export default BottomContainer;


const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 70vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: row;
`;

const LeftContainer = styled.div`
  width: 60%;
  border-right: 3px solid #D9D9D9;
`;

const RightContainer = styled.div`
  width: 40%;
`