import axios, { Method } from "axios";
import { QueryCache, QueryClient } from "react-query";

export const originalBaseURL = process.env.REACT_APP_API_URL || "";
// export const originalBaseURL = "https://dev.mphotypeta.com" || "";

// Attention: DO NOT REMOVE THIS CODE
// Please create .env file
// add this env variable:
// REACT_APP_API_URL=http://localhost:3000

// export const localStorageAPI = localStorage.getItem("__API_URL");
// console.log(localStorageAPI);
export const baseURL =  originalBaseURL;
// export const baseURL = originalBaseURL;


// let authObj: { [key: string]: string } = {};
// export const xAuth = localStorage.getItem("x_auth");

// if (xAuth) {
//   authObj["X-AUTHORIZATION"] = `Bearer ${xAuth}`;
// }

let authObj: { [key: string]: string } = {};
export const xAuth = localStorage.getItem("token");

if (xAuth) {
  authObj["AUTHORIZATION"] = `Bearer ${xAuth}`;
}


const request = axios.create({
  withCredentials: false,
  baseURL,
  headers: {
    ...authObj,
  },
});

request.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      // @ts-ignore
      console.log("401: surpressed refresh");

      if (window.location.pathname !== "/login") {
        if (window.location.pathname === "/") {
          console.log('??????')
          return;
        }

        localStorage.removeItem("token");
        window.location.href = "/login";
      }
    } else {
      // @ts-ignore
      // TODO
      console.log(`REQUEST FAILED. DO SOMETHING`);
      throw error;
    }
  }
);

const queryCache = new QueryCache();

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
  queryCache,
});

export default request;
