import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";


const Elem: React.FC<any> = ({ children }) => {

  return (
    <h1>
      곧 업데이트 됩니다.
    </h1>
  );
};

export default Elem;
